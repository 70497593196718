import React, { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';

function Header({ isLoggedIn, logoutUser, showNavbar = true }) {
    const [menuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    return (
        <nav className="bg-white shadow-lg sticky top-0 z-50">
            <div className="container mx-auto px-4 py-4 flex justify-between items-center">
                {/* Logo */}
                <Link to="/" className="text-black text-xl font-bold">
                    <img src="/logo.svg" alt="LabelBoost Logo" className="h-10" />
                </Link>

                {/* Show Navbar only if showNavbar is true */}
                {showNavbar && (
                    <>
                        {/* Hamburger button */}
                        <div className="lg:hidden">
                            <button
                                onClick={toggleMenu}
                                type="button"
                                className="text-black focus:outline-none"
                                aria-label="Toggle menu"
                            >
                                <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7" />
                                </svg>
                            </button>
                        </div>

                        {/* Fullscreen menu overlay when in mobile mode */}
                        <div
                            className={`${
                                menuOpen ? 'block' : 'hidden'
                            } absolute top-0 left-0 w-full h-screen bg-white text-blue-600 lg:hidden z-40`}
                        >
                            <div className="container mx-auto px-4 py-4 flex justify-between items-center">
                                {/* Logo in mobile */}
                                <Link to="/" className="text-black">
                                    <img src="/logo.svg" alt="LabelBoost Logo" className="h-10" />
                                </Link>

                                {/* Close button */}
                                <button
                                    onClick={toggleMenu}
                                    type="button"
                                    className="text-black focus:outline-none"
                                    aria-label="Close menu"
                                >
                                    <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                                    </svg>
                                </button>
                            </div>

                            {/* Menu items */}
                            <ul className="mt-8 space-y-6 text-center">
                                {isLoggedIn ? (
                                    <>
                                        <li>
                                            <NavLink to="/home" className="text-blue-600 hover:text-blue-800 text-xl" onClick={toggleMenu}>
                                                Home
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/campaigns" className="text-blue-600 hover:text-blue-800 text-xl" onClick={toggleMenu}>
                                                Campaigns
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/artists" className="text-blue-600 hover:text-blue-800 text-xl" onClick={toggleMenu}>
                                                Artists
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/events" className="text-blue-600 hover:text-blue-800 text-xl" onClick={toggleMenu}>
                                                Events
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/billing" className="text-blue-600 hover:text-blue-800 text-xl" onClick={toggleMenu}>
                                                Billing
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink
                                                to="/login"
                                                className="text-blue-600 hover:text-blue-800 text-xl"
                                                onClick={() => {
                                                    logoutUser();
                                                    toggleMenu();
                                                }}
                                            >
                                                Logout
                                            </NavLink>
                                        </li>
                                    </>
                                ) : (
                                    <li>
                                        <NavLink to="/login" className="text-blue-600 hover:text-blue-800 text-xl" onClick={toggleMenu}>
                                            Login
                                        </NavLink>
                                    </li>
                                )}
                            </ul>
                        </div>

                        {/* Desktop Navigation */}
                        <div className="hidden lg:flex lg:items-center lg:space-x-6">
                            {isLoggedIn ? (
                                <>
                                    <NavLink to="/home" className="text-blue-600 hover:text-blue-800">
                                        Home
                                    </NavLink>
                                    <NavLink to="/campaigns" className="text-blue-600 hover:text-blue-800">
                                        Campaigns
                                    </NavLink>
                                    <NavLink to="/artists" className="text-blue-600 hover:text-blue-800">
                                        Artists
                                    </NavLink>
                                    <NavLink to="/events" className="text-blue-600 hover:text-blue-800">
                                        Events
                                    </NavLink>
                                    <NavLink to="/billing" className="text-blue-600 hover:text-blue-800">
                                        Billing
                                    </NavLink>
                                    <NavLink
                                        to="/login"
                                        onClick={logoutUser}
                                        className="text-blue-600 hover:text-blue-800"
                                    >
                                        Logout
                                    </NavLink>
                                </>
                            ) : (
                                <NavLink to="/login" className="text-blue-600 hover:text-blue-800">
                                    Login
                                </NavLink>
                            )}
                        </div>
                    </>
                )}
            </div>
        </nav>
    );
}

export default Header;
