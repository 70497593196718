import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import EventService from '../../services/EventService'; // Assuming you're using EventService for the API call
import Header from '../../core/Header';

function CheckoutConfirmation() {
    const { id } = useParams();
    const [loading, setLoading] = useState(true);
    const [message, setMessage] = useState('');

    useEffect(() => {
        const confirmOrder = async () => {
            try {
                const response = await EventService.confirmEvent(id); // Assuming confirmEvent is in EventService
                setMessage(response.data.message); // Adjust based on the actual response structure
            } catch (error) {
                setMessage('There was an error confirming your order.');
                console.error('Error confirming order:', error);
            } finally {
                setLoading(false);
            }
        };

        confirmOrder();
    }, [id]);

    return (
        <div className="bg-gray-50 min-h-screen">
            <Header showNavbar={false} />
            <div className="container mx-auto py-16">
                <div className="bg-white rounded-lg shadow-lg p-8 text-center">
                    {loading ? (
                        <div className="flex justify-center items-center">
                            <div className="spinner-border text-blue-600" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                        </div>
                    ) : (
                        <h3 className="text-2xl font-bold text-gray-800 mb-4">
                            {message}
                        </h3>
                    )}
                </div>
            </div>
        </div>
    );
}

export default CheckoutConfirmation;
