import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import ArtistService from '../services/ArtistService';

function ArtistSelect({ placeholder = 'Select artist', multiple = false, onArtistChange, value }) {
    const [artists, setArtists] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedArtists, setSelectedArtists] = useState(multiple ? [] : null);

    useEffect(() => {
        const fetchArtists = async () => {
            try {
                const response = await ArtistService.getArtistsForAuthenticatedUser();
                setArtists(response.data);

                // Set the initial selected value if value is passed in props
                if (value) {
                    const selectedValue = multiple
                        ? response.data.filter(artist => value.includes(artist.id)).map(artist => ({
                            value: artist.id,
                            label: artist.name,
                        }))
                        : response.data.find(artist => artist.id === value);

                    setSelectedArtists(
                        multiple ? selectedValue : { value: selectedValue.id, label: selectedValue.name }
                    );
                }
            } catch (error) {
                console.error('Error fetching artists:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchArtists();
    }, [value, multiple]);

    const handleChange = (selectedOption) => {
        setSelectedArtists(selectedOption);
        onArtistChange(selectedOption);
    };

    const options = artists.map((artist) => ({
        value: artist.id,
        label: artist.name,
    }));

    return (
        <Select
            isMulti={multiple}
            options={options}
            isLoading={loading}
            isClearable={true}
            placeholder={placeholder}
            value={selectedArtists}
            onChange={handleChange}
            className="basic-multi-select"
            classNamePrefix="select"
        />
    );
}

export default ArtistSelect;
