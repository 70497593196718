import React from 'react';
import Header from '../../core/Header';

function CheckoutSuccess() {
    return (
        <div className="bg-gray-50 min-h-screen">
            <Header showNavbar={false} />
            <div className="container mx-auto py-16">
                <div className="bg-white rounded-lg shadow-lg p-8 text-center">
                    <h3 className="text-2xl font-bold text-gray-800 mb-4">
                        Thank you for your order!
                    </h3>
                    <p className="text-lg text-gray-600 mb-4">
                        Check your email for further information, and don’t forget to confirm within the next 24 hours!
                    </p>
                    <p className="text-lg text-gray-600">
                        Make sure to also check your spam folder!
                    </p>
                </div>
            </div>
        </div>
    );
}

export default CheckoutSuccess;
