import React from 'react';

function PrivacyPolicy() {
    return (
        <div className="bg-gray-50 min-h-screen">
            <div className="container mx-auto py-12 px-4 sm:px-6 lg:px-8">
                <div className="bg-white shadow-lg rounded-lg p-8 max-w-2xl mx-auto">
                    <h1 className="text-3xl font-bold text-gray-800 mb-6 text-center">Privacy Policy</h1>
                    <p className="text-lg text-gray-600 mb-4">
                        Your privacy is important to us. This privacy policy explains how we collect, use, and protect your personal data in compliance with the General Data Protection Regulation (GDPR).
                    </p>

                    {/* GDPR Compliance Section */}
                    <div className="border-t border-gray-200 my-4"></div>
                    <h2 className="text-xl font-semibold text-gray-700 mb-2">Data Protection and GDPR Compliance</h2>
                    <p className="text-gray-600 mb-4">
                        LabelBoost takes your privacy seriously and ensures that all personal data is handled in accordance with the GDPR. This means:
                    </p>
                    <ul className="list-disc list-inside text-gray-600 mb-4">
                        <li>We only collect data that is necessary for the purposes of providing our services.</li>
                        <li>All data is stored securely and handled responsibly.</li>
                        <li>You have the right to access, rectify, or delete your data at any time. Please email <a href="mailto:max@labelboost.net" className="text-blue-500 hover:text-blue-700">max@labelboost.net</a> for any requests regarding your personal data.</li>
                        <li>We do not share your data with third parties without your explicit consent, except as required by law.</li>
                    </ul>

                    {/* Spotify Data Section */}
                    <div className="border-t border-gray-200 my-4"></div>
                    <h2 className="text-xl font-semibold text-gray-700 mb-2">How We Use Your Data: Spotify</h2>
                    <p className="text-gray-600 mb-4">
                        <strong>IMPORTANT:</strong> The information below must be read in conjunction with our privacy policy which governs our overall use of your personal data.
                        <br />
                        When you participate in a ‘pre-save’, ‘pre-add’, ‘follow’, or other artist-based campaign (“Campaign”) operated by LabelBoost, you give us permission to connect with Spotify to access your user account in the manner described below.
                    </p>
                    <h3 className="text-lg font-semibold text-gray-700 mb-2">Information Obtained by LabelBoost</h3>
                    <p className="text-gray-600 mb-4">
                        After you approve our connection with Spotify, we may access and store some or all of the following information from your Spotify account:
                    </p>
                    <ul className="list-disc list-inside text-gray-600 mb-4">
                        <li>Your registered email address.</li>
                        <li>Information about your account, such as your country and location.</li>
                        <li>Activity data, including the playlists and artists you follow, recently played tracks, and more.</li>
                    </ul>

                    <h3 className="text-lg font-semibold text-gray-700 mb-2">Actions Taken by LabelBoost</h3>
                    <p className="text-gray-600 mb-4">
                        Depending on the type of campaign, LabelBoost may take actions on your account, such as:
                    </p>
                    <ul className="list-disc list-inside text-gray-600 mb-4">
                        <li>Adding or removing items from your library.</li>
                        <li>Creating or editing playlists, or following artists.</li>
                        <li>Subscribing to channels or other features on Spotify.</li>
                    </ul>
                    <p className="text-gray-600 mb-4">
                        To revoke LabelBoost’s access to your Spotify account, please visit Spotify's website and follow the relevant instructions. You can also email <a href="mailto:max@labelboost.net" className="text-blue-500 hover:text-blue-700">max@labelboost.net</a> to request the deletion of your information from our systems.
                    </p>

                    {/* E-Commerce Section */}
                    <div className="border-t border-gray-200 my-4"></div>
                    <h2 className="text-xl font-semibold text-gray-700 mb-2">E-Commerce and Ticket Sales</h2>
                    <p className="text-gray-600 mb-4">
                        LabelBoost also processes personal data when you make purchases through our platform, including for ticket sales. Your personal and payment information is collected and used solely for the purpose of completing your transaction and fulfilling your order. We use industry-standard encryption and security measures to protect your data during transactions.
                    </p>
                    <p className="text-gray-600 mb-4">
                        If you have any concerns about how your data is being handled during the purchase process, or if you would like to revoke your consent for storing your purchase data, please contact us at <a href="mailto:max@labelboost.net" className="text-blue-500 hover:text-blue-700">max@labelboost.net</a>.
                    </p>

                    </div>
            </div>
        </div>
    );
}

export default PrivacyPolicy;
