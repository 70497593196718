import React, { useState } from 'react';
import AuthService from "../services/AuthService";
const PasswordForgot = () => {
    const [email, setEmail] = useState('');
    const [passwordRequested, setPasswordRequested] = useState(false);
    const [error, setError] = useState('');

    const requestPassword = async (e) => {
        e.preventDefault();
        try {
            await AuthService.requestPasswordReset(email);
            setPasswordRequested(true);
        } catch (error) {
            setError('Failed to request password reset.');
        }
    };

    return (
        <div className="min-h-screen flex items-center justify-center bg-gray-100">
            <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-sm">
                {!passwordRequested ? (
                    <>
                        <h2 className="text-2xl font-bold mb-6 text-center text-gray-800">Request a New Password</h2>
                        {error && <div className="mb-4 text-red-500 text-sm">{error}</div>}
                        <form onSubmit={requestPassword}>
                            <div className="mb-4">
                                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
                                    Email
                                </label>
                                <input
                                    type="email"
                                    id="email"
                                    placeholder="Enter your email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    className="w-full px-4 py-2 border rounded-md focus:ring focus:ring-indigo-300"
                                    required
                                />
                            </div>
                            <button
                                type="submit"
                                className="w-full py-2 rounded-md bg-blue-600 text-white font-semibold hover:bg-blue-700 transition duration-200"
                            >
                                Request Password Reset
                            </button>
                        </form>
                    </>
                ) : (
                    <div className="text-center">
                        <h3>If an account with that email exists, a reset link has been sent.</h3>
                    </div>
                )}
            </div>
        </div>
    );
};

export default PasswordForgot;
