import React, { useEffect, useState } from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import CampaignService from '../../services/CampaignService';
import Header from '../../core/Header';
import Globals from "../../core/globals";

function CampaignDetailed() {
    const { id } = useParams();
    const [campaign, setCampaign] = useState(null);
    const [loading, setLoading] = useState(true);
    const [topItems, setTopItems] = useState(null);
    const [loadingTopItems, setLoadingTopItems] = useState(false);
    const [loadingLottery, setLoadingLottery] = useState(false);
    const [loadingCsv, setLoadingCsv] = useState(false);
    const [loadingCheck, setLoadingCheck] = useState(false);
    const [containsText, setContainsText] = useState('');
    const [error, setError] = useState('');
    const [topItemsError, setTopItemsError] = useState('');
    const [lotteryError, setLotteryError] = useState('');
    const [csvError, setCsvError] = useState('');
    const [checkContainsError, setCheckContainsError] = useState('');

    const navigate = useNavigate();

    useEffect(() => {
        const fetchCampaign = async () => {
            try {
                const response = await CampaignService.getCampaignById(id);
                setCampaign(response.data);
            } catch (error) {
                setError("Error loading campaign: " + error.message);
                console.error("Error loading campaign:", error);
            } finally {
                setLoading(false);
            }
        };
        fetchCampaign();
    }, [id]);

    const getTopItems = async () => {
        setLoadingTopItems(true);
        setTopItemsError('');
        try {
            const response = await CampaignService.getTopItems(id);
            setTopItems(response.data);
        } catch (error) {
            setTopItemsError("Error loading top items: " + error.response.data);
            console.error("Error loading top items:", error);
        } finally {
            setLoadingTopItems(false);
        }
    };

    const performLottery = async () => {
        setLoadingLottery(true);
        setLotteryError('');
        try {
            const response = await CampaignService.getLotteryWinner(id);
            setCampaign((prev) => ({ ...prev, lotteryWinner: response.data }));
        } catch (error) {
            setLotteryError("Error performing lottery: " + error.response.data);
            console.error("Error performing lottery:", error);
        } finally {
            setLoadingLottery(false);
        }
    };

    const exportCsv = async () => {
        setLoadingCsv(true);
        setCsvError('');
        try {
            const response = await CampaignService.exportCsv(id);
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const a = document.createElement('a');
            a.href = url;
            a.download = `${campaign.name}.csv`;
            document.body.appendChild(a);
            a.click();
            a.remove();
        } catch (error) {
            setCsvError("Error exporting CSV: " + error.response.data);
            console.error("Error exporting CSV:", error);
        } finally {
            setLoadingCsv(false);
        }
    };

    const checkContains = async () => {
        setLoadingCheck(true);
        setCheckContainsError('');
        try {
            const response = await CampaignService.checkContains(id);
            setContainsText(
                `Out of ${response.data.numberOfPreSaves} pre-saves on Spotify, ${response.data.stillContaining} still have the song in their library. This is ${Math.round((response.data.stillContaining / response.data.numberOfPreSaves) * 100)}%.`
            );
        } catch (error) {
            setCheckContainsError("Error checking contains: " + error.response.data);
            console.error("Error checking contains:", error);
        } finally {
            setLoadingCheck(false);
        }
    };

    const jumpToLandingPage = () => {
        navigate(campaign.urlSuffix);
    };

    const getArtistNamesFormatted = () => {
        const artists = [...(campaign.featureArtistsNames || [])];
        if (artists.length === 0) {
            return campaign.artistName;
        } else if (artists.length === 1) {
            return `${campaign.artistName} and ${artists[0]}`;
        } else {
            const lastArtist = artists.pop();
            return `${campaign.artistName}, ${artists.join(', ')}, and ${lastArtist}`;
        }
    };

    return (
        <div className="bg-gray-50 min-h-screen">
            <Header isLoggedIn={true} logoutUser={() => console.log('Logged out')} />
            <div className="container mx-auto py-8">
                {loading ? (
                    <div className="text-center">
                        <h3 className="text-lg font-semibold text-gray-700">
                            <span className="spinner-border spinner-border-sm mr-2"></span>
                            Loading Campaign...
                        </h3>
                    </div>
                ) : (
                    <div className="flex flex-col md:flex-row bg-white rounded-lg shadow-lg overflow-hidden">
                        {error && (
                            <div className="text-red-500 text-center p-4">
                                {error}
                            </div>
                        )}
                        {/* Campaign Image */}
                        <div className="md:w-1/2 p-4">
                            <img
                                src={campaign.artworkUrl}
                                alt={campaign.name}
                                className="w-full h-auto rounded-lg shadow-md object-cover"
                            />
                        </div>

                        {/* Campaign Info */}
                        <div className="md:w-1/2 p-6">
                            <h2 className="text-3xl font-bold text-gray-800 mb-4">
                                {campaign.name} - {getArtistNamesFormatted()}
                            </h2>
                            <div className="mb-4">
                                <h4 className="text-lg font-medium text-gray-700">
                                    Spotify Pre Saves: {campaign.numberOfSaves}
                                </h4>
                                <h4 className="text-lg font-medium text-gray-700">
                                    Apple Music Pre Saves: {campaign.numberOfSavesApple}
                                </h4>
                                {campaign.isReleased ? (
                                    <>
                                        <h4 className="text-lg font-medium text-gray-700">
                                            Campaign (after release): {campaign.numberOfCampaignClicksPostRelease}
                                        </h4>
                                        <h4 className="text-lg font-medium text-gray-700">
                                            Spotify: {campaign.numberOfSpotifyClicks}
                                        </h4>
                                        <h4 className="text-lg font-medium text-gray-700">
                                            Apple Music: {campaign.numberOfAppleMusicClicks}
                                        </h4>
                                        <h4 className="text-lg font-medium text-gray-700">
                                            Deezer: {campaign.numberOfDeezerClicks}
                                        </h4>
                                        <h4 className="text-lg font-medium text-gray-700">
                                            Click Through Rate: {Math.round(
                                            ((campaign.numberOfSpotifyClicks + campaign.numberOfDeezerClicks +
                                                campaign.numberOfAppleMusicClicks) / campaign.numberOfCampaignClicksPostRelease) * 100
                                        )}%
                                        </h4>
                                    </>
                                ) : (
                                    <h4 className="text-lg font-medium text-gray-700">
                                        Pre Save Page Clicks: {campaign.numberOfCampaignClicksPreRelease}
                                    </h4>
                                )}
                            </div>

                            <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
                                <button
                                    className={`bg-blue-500 text-white rounded-md px-4 py-2 hover:bg-blue-600 transition-colors duration-200`}
                                    onClick={jumpToLandingPage}
                                >
                                    <i className="bi bi-file-text"></i> Link
                                </button>
                                <button
                                    className={`bg-blue-500 text-white rounded-md px-4 py-2 ${loadingTopItems || topItems ? 'opacity-50 cursor-not-allowed' : 'hover:bg-blue-600 transition-colors duration-200'}`}
                                    onClick={getTopItems}
                                    disabled={loadingTopItems || topItems}
                                >
                                    {loadingTopItems && (
                                        <span className="spinner-border spinner-border-sm mr-2"></span>
                                    )}
                                    <i className="bi bi-bar-chart"></i> Analytics
                                </button>
                                <button
                                    className={`bg-blue-500 text-white rounded-md px-4 py-2 ${loadingLottery || campaign.lotteryWinner ? 'opacity-50 cursor-not-allowed' : 'hover:bg-blue-600 transition-colors duration-200'}`}
                                    onClick={performLottery}
                                    disabled={loadingLottery || campaign.lotteryWinner}
                                >
                                    {loadingLottery && (
                                        <span className="spinner-border spinner-border-sm mr-2"></span>
                                    )}
                                    <i className="bi bi-gift"></i> Lottery
                                </button>
                                <button
                                    className={`bg-blue-500 text-white rounded-md px-4 py-2 ${loadingCsv ? 'opacity-50 cursor-not-allowed' : 'hover:bg-blue-600 transition-colors duration-200'}`}
                                    onClick={exportCsv}
                                    disabled={loadingCsv}
                                >
                                    {loadingCsv && (
                                        <span className="spinner-border spinner-border-sm mr-2"></span>
                                    )}
                                    <i className="bi bi-table"></i> Export CSV
                                </button>
                                {campaign.isReleased && (
                                    <button
                                        className={`bg-blue-500 text-white rounded-md px-4 py-2 ${loadingCheck ? 'opacity-50 cursor-not-allowed' : 'hover:bg-blue-600 transition-colors duration-200'}`}
                                        onClick={checkContains}
                                        disabled={loadingCheck}
                                    >
                                        {loadingCheck && (
                                            <span className="spinner-border spinner-border-sm mr-2"></span>
                                        )}
                                        <i className="bi bi-archive"></i> Check Contains
                                    </button>
                                )}
                            </div>

                            <div className="mt-4">
                                {topItemsError && (
                                    <div className="text-red-500 text-sm mt-2">
                                        {topItemsError}
                                    </div>
                                )}
                                {lotteryError && (
                                    <div className="text-red-500 text-sm mt-2">
                                        {lotteryError}
                                    </div>
                                )}
                                {csvError && (
                                    <div className="text-red-500 text-sm mt-2">
                                        {csvError}
                                    </div>
                                )}
                                {checkContainsError && (
                                    <div className="text-red-500 text-sm mt-2">
                                        {checkContainsError}
                                    </div>
                                )}
                            </div>

                            {campaign.lotteryWinner && (
                                <h4 className="text-lg font-medium text-gray-700 mt-4">
                                    Winner of the lottery: {campaign.lotteryWinner}
                                </h4>
                            )}

                            {containsText && (
                                <p className="text-lg font-medium text-gray-700 mt-4">{containsText}</p>
                            )}

                            {topItems && (
                                <div className="mt-6">
                                    <h4 className="text-xl font-bold text-gray-800">Your users' top artists:</h4>
                                    <ul className="list-disc list-inside text-gray-700 ml-5">
                                        {topItems.artists && topItems.artists.length > 0 ? (
                                            topItems.artists.map((artist, index) => (
                                                <li key={index}>{artist}</li>
                                            ))
                                        ) : (
                                            <li>No top artists available.</li>
                                        )}
                                    </ul>

                                    <h4 className="text-xl font-bold text-gray-800 mt-4">Your users' top genres:</h4>
                                    <ul className="list-disc list-inside text-gray-700 ml-5">
                                        {topItems.genre && topItems.genre.length > 0 ? (
                                            topItems.genre.map((genre, index) => (
                                                <li key={index}>{genre}</li>
                                            ))
                                        ) : (
                                            <li>No top genres available.</li>
                                        )}
                                    </ul>

                                    <h4 className="text-xl font-bold text-gray-800 mt-4">Your users' top songs:</h4>
                                    <ul className="list-disc list-inside text-gray-700 ml-5">
                                        {topItems.songs && topItems.songs.length > 0 ? (
                                            topItems.songs.map((song, index) => (
                                                <li key={index}>{song}</li>
                                            ))
                                        ) : (
                                            <li>No top songs available.</li>
                                        )}
                                    </ul>
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default CampaignDetailed;
