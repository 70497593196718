class Globals {
    constructor() {
        this.backendUri = this.findBackendUrl();
        this.frontendUri = this.findFrontendUrl();
        this.stripePublicKey = this.findStripeKey();
        this.stripeDashboardUrl = this.findStripeDashboardUrl();
        this.labelPlanPayment = this.findLabelPlanPayment();
        this.artistPlanPayment = this.findArtistPlanPayment();
    }

    findBackendUrl() {
        if (window.location.port === '3000') { // assuming your React app runs on port 3000 locally
            return 'http://localhost:8080/api/v1';
        } else {
            // assume deployed somewhere and backend is available at the same host/port as frontend
            return 'https://api.labelboost.net/api/v1';
        }
    }

    findFrontendUrl() {
        if (window.location.port === '3000') { // assuming your React app runs on port 3000 locally
            return 'http://localhost:3000/';
        } else {
            // assume deployed somewhere and backend is available at the same host/port as frontend
            return 'https://labelboost.net/';
        }
    }

    findStripeKey() {
        if (window.location.port === '3000') {
            return 'pk_test_51P5Zbk049jo8c1UhOvu2DMnvZ0LLYeoOHkoT0UQrvk9AXJ8Zp0FwaIrLO0QiPqSc6uIOGIFodG2GbdvVgO3bmNgp00ea58jyh1';
        } else {
            return 'pk_live_51P5Zbk049jo8c1UhQoe6xkLPItcqC9ksDdbejIOrNL7vJLFRmawX1J9gAVNAwy8JxWu79cM7LF9gEQdOfkEf9NkV00YAggHiYZ';
        }
    }

    findLabelPlanPayment() {
        if (window.location.port === '3000') {
            return 'https://buy.stripe.com/test_9AQ9BQ88saBG5fWbII';
        } else {
            return 'https://buy.stripe.com/3csaFV1k59HIfQY6op';
        }
    }

    findArtistPlanPayment() {
        if (window.location.port === '3000') {
            return 'https://buy.stripe.com/test_6oE8xMfAU1167o48wy';
        } else {
            return 'https://buy.stripe.com/7sI5lB2o9aLMfQYaEE';
        }
    }

    findStripeDashboardUrl() {
        if (window.location.port === '3000') {
            return 'https://billing.stripe.com/p/login/test_fZe7wl4DMgSq6xW4gg';
        } else {
            return 'https://billing.stripe.com/p/login/cN2aHndEo5pZ8wgeUU';
        }
    }

}

export default new Globals();
