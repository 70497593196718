import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import CampaignService from '../../services/CampaignService';
import Header from "../../core/Header";

function SavePageRedirect() {
    const [loading, setLoading] = useState(true);
    const [user, setUser] = useState(null);
    const [success, setSuccess] = useState(true);
    const location = useLocation();

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const successParam = searchParams.get('success');
        const campaignId = searchParams.get('campaign_id');
        const code = searchParams.get('code');
        const state = searchParams.get('state');

        if (successParam && campaignId) {
            // Fetch campaign details by URL suffix
            CampaignService.getCampaignByUrlSuffix(campaignId)
                .then(res => {
                    setLoading(false);
                    setSuccess(true);
                    setUser({
                        artworkUrl: res.data.artworkUrl,
                        songTitle: res.data.campaignTitle
                    });
                })
                .catch(error => {
                    setLoading(false);
                    setSuccess(false);
                    console.error("Error fetching campaign:", error);
                });
        } else if (code && state) {
            // Register Spotify user
            CampaignService.registerUser(code, state)
                .then(res => {
                    setUser(res.data);
                    setLoading(false);
                })
                .catch(error => {
                    setLoading(false);
                    setSuccess(false);
                    console.error("Error registering user:", error);
                });
        }
    }, [location.search]);

    return (
        <div className="min-h-screen flex flex-col">
            <Header showNavbar={false} />

            <div className="flex-grow flex items-center justify-center bg-gray-100">
                <div className="text-center">
                    {loading && (
                        <div className="flex justify-center items-center">
                            <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 border-t-4 border-blue-600 rounded-full"></div>
                        </div>
                    )}
                    {!loading && success && user && (
                        <>
                            <div className="mb-4">
                                <img src={user.artworkUrl} alt="Artwork" className="mx-auto w-64 h-auto object-cover rounded-lg shadow-md" />
                            </div>
                            <div>
                                <h3 className="text-xl font-semibold text-gray-700">Thank you for saving {user.songTitle}!</h3>
                            </div>
                        </>
                    )}
                    {!loading && !success && (
                        <div>
                            <h3 className="text-xl font-semibold text-red-500">Something went wrong, please try again later!</h3>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default SavePageRedirect;
