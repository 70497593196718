import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import EventService from '../../services/EventService';
import Header from '../../core/Header';
import QrScanner from 'react-qr-scanner';

function EventCheckin() {
    const { id: eventId } = useParams();
    const [loading, setLoading] = useState(true);
    const [event, setEvent] = useState(null);
    const [scannerEnabled, setScannerEnabled] = useState(false);
    const [changedTickets, setChangedTickets] = useState([]);
    const [submitting, setSubmitting] = useState(false);
    const [error, setError] = useState('');

    useEffect(() => {
        const loadEventData = async () => {
            try {
                const response = await EventService.getEventCheckIn(eventId);
                setEvent(response.data);
            } catch (error) {
                setError('Failed to load tickets');
            } finally {
                setLoading(false);
            }
        };
        loadEventData();
    }, [eventId]);

    const handleTicketCheckboxChange = (ticket) => {
        const updatedTickets = event.tickets.map(t => {
            if (t.ticketId === ticket.ticketId) {
                return { ...t, scanned: !t.scanned };
            }
            return t;
        });
        setEvent({ ...event, tickets: updatedTickets });

        const updatedChangedTickets = [...changedTickets];
        const index = updatedChangedTickets.findIndex(t => t.ticketId === ticket.ticketId);

        if (index > -1) {
            updatedChangedTickets[index].scanned = !updatedChangedTickets[index].scanned;
        } else {
            updatedChangedTickets.push({ ticketId: ticket.ticketId, scanned: !ticket.scanned });
        }

        setChangedTickets(updatedChangedTickets);
    };

    const submitTickets = async () => {
        setSubmitting(true);
        try {
            await EventService.submitChangedTickets(changedTickets);
            setChangedTickets([]);
        } catch (error) {
            setError('Failed to submit tickets');
        } finally {
            setSubmitting(false);
        }
    };

    const submitScannedTicket = async (ticketUrl) => {
        const ticketId = ticketUrl.split('/').pop();
        setSubmitting(true);
        try {
            await EventService.scanTicket(ticketId, eventId);
            setScannerEnabled(false); // Close the scanner
            setError(''); // Clear the error if successful
        } catch (error) {
            setError('Failed to scan ticket'); // Set error without showing a toaster
            setScannerEnabled(false); // Close the scanner on error
        } finally {
            setSubmitting(false);
        }
    };

    const toggleScanner = () => {
        setScannerEnabled((prev) => !prev);
    };

    const handleScan = (data) => {
        if (data) {
            submitScannedTicket(data.text);
        }
    };

    const handleError = (err) => {
        setError('Scanner error');
        console.error(err);
    };

    return (
        <div className="bg-gray-50 min-h-screen">
            <Header isLoggedIn={true} logoutUser={() => console.log('Logged out')} />
            <div className="container mx-auto py-8">
                {loading ? (
                    <div className="text-center">
                        <h3 className="text-lg font-semibold text-gray-700">
                            <span className="spinner-border spinner-border-sm mr-2"></span>
                            Loading Event...
                        </h3>
                    </div>
                ) : event ? (
                    <div className="flex flex-col md:flex-row bg-white rounded-lg shadow-lg overflow-hidden">
                        {error && (
                            <div className="text-red-500 text-center p-4">
                                {error}
                            </div>
                        )}

                        <div className="w-full p-6">
                            <h2 className="text-3xl font-bold text-gray-800 mb-4">{event.eventName}</h2>
                            <p className="text-lg font-medium text-gray-700 mb-2">
                                Tickets sold: {event.ticketsSold}
                            </p>
                            <p className="text-lg font-medium text-gray-700 mb-4">
                                Tickets scanned: {event.ticketsChecked}
                            </p>

                            <button
                                className="bg-blue-500 text-white rounded-md px-4 py-2 hover:bg-blue-600 transition-colors duration-200 mb-6"
                                onClick={toggleScanner}
                            >
                                {scannerEnabled ? 'Disable Scanner' : 'Enable Scanner'}
                            </button>

                            {scannerEnabled && (
                                <div className="mb-6">
                                    <QrScanner
                                        delay={300}
                                        onError={handleError}
                                        onScan={handleScan}
                                        style={{ width: '100%' }}
                                    />
                                </div>
                            )}

                            <div className="mb-8">
                                <h4 className="text-xl font-semibold mb-2">Paid Tickets</h4>
                                <table className="table-auto w-full mb-4">
                                    <thead>
                                    <tr>
                                        <th className="px-4 py-2 text-left">Ticket Name</th>
                                        <th className="px-4 py-2 text-left">Scanned</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {event.tickets
                                        .filter((ticket) => ticket.paid)
                                        .map((ticket) => (
                                            <tr key={ticket.ticketId}>
                                                <td className="px-4 py-2">{ticket.ticketName}</td>
                                                <td className="px-4 py-2">
                                                    <input
                                                        type="checkbox"
                                                        checked={ticket.scanned}
                                                        onChange={() => handleTicketCheckboxChange(ticket)}
                                                    />
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>

                            <div className="mb-8">
                                <h4 className="text-xl font-semibold mb-2">Unpaid Tickets</h4>
                                <table className="table-auto w-full mb-4">
                                    <thead>
                                    <tr>
                                        <th className="px-4 py-2 text-left">Ticket Name</th>
                                        <th className="px-4 py-2 text-left">Scanned</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {event.tickets
                                        .filter((ticket) => !ticket.paid)
                                        .map((ticket) => (
                                            <tr key={ticket.ticketId}>
                                                <td className="px-4 py-2">{ticket.ticketName}</td>
                                                <td className="px-4 py-2">
                                                    <input
                                                        type="checkbox"
                                                        checked={ticket.scanned}
                                                        onChange={() => handleTicketCheckboxChange(ticket)}
                                                    />
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>

                            <button
                                className="bg-green-600 text-white rounded-md px-4 py-2 hover:bg-green-700 transition-colors duration-200"
                                onClick={submitTickets}
                                disabled={submitting}
                            >
                                {submitting ? 'Submitting...' : 'Submit Scans'}
                            </button>
                        </div>
                    </div>
                ) : (
                    <h3 className="text-xl font-bold text-center">No event data available</h3>
                )}
            </div>
        </div>
    );
}

export default EventCheckin;
