import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Header from '../../core/Header';
import ArtistService from '../../services/ArtistService';
import ArtistSelect from '../../core/ArtistSelect';
import { debounce } from 'lodash';
import Select from "react-select";

function ArtistCreate() {
    const [form, setForm] = useState({
        artistname: '',
        description: '',
        tiktokUrl: '',
        instagramUrl: '',
        facebookUrl: '',
        spotifyId: '',
        id: '',
        artistExists: true,
        invoiceName: '',
        invoiceStreet: '',
        invoiceTown: '',
        invoiceCountry: '',
        invoiceZipCode: '',
        iban: '',
    });
    const [filteredArtists, setFilteredArtists] = useState([]);
    const [loadingArtists, setLoadingArtists] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [showArtistDropdown, setShowArtistDropdown] = useState(false);

    const navigate = useNavigate();
    const { id } = useParams();

    const countryOptions = [
        { value: 'Austria', label: 'Austria' },
    ];

    useEffect(() => {
        if (id) {
            setEditMode(true);
            ArtistService.getArtistById(id)
                .then(response => {
                    setForm({
                        artistname: response.data.name,
                        description: response.data.description,
                        tiktokUrl: response.data.tiktokUrl,
                        instagramUrl: response.data.instagramUrl,
                        facebookUrl: response.data.facebookUrl,
                        spotifyId: response.data.spotifyId,
                        id: response.data.id,
                        invoiceName: response.data.invoiceName,
                        invoiceStreet: response.data.invoiceStreet,
                        invoiceTown: response.data.invoiceTown,
                        invoiceCountry: response.data.invoiceCountry || '',
                        invoiceZipCode: response.data.invoiceZipCode,
                        iban: response.data.iban,
                    });
                })
                .catch(error => {
                    console.error('Error fetching artist:', error);
                    setErrorMessage('Failed to load artist data.');
                });
        }
    }, [id]);

    const searchArtists = async (query) => {
        if (query.length > 2) {
            setLoadingArtists(true);
            try {
                const response = await ArtistService.searchArtists(query);
                setFilteredArtists(response.data);
                setShowArtistDropdown(true);
            } catch (error) {
                console.error('Error searching artists:', error);
                setErrorMessage('Error searching for artists.');
            } finally {
                setLoadingArtists(false);
            }
        } else {
            setFilteredArtists([]);
            setShowArtistDropdown(false);
        }
    };

    const debouncedSearch = useCallback(
        debounce((query) => searchArtists(query), 500),
        []
    );

    const handleArtistSearch = (e) => {
        const query = e.target.value;
        setForm((prevForm) => ({
            ...prevForm,
            artistSearch: query,
        }));
        debouncedSearch(query);
    };

    const handleArtistSelect = (selectedArtist) => {
        setForm((prevForm) => ({
            ...prevForm,
            spotifyId: selectedArtist.id,
            artistSearch: selectedArtist.name,
        }));
        setShowArtistDropdown(false);
    };

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setForm((prevForm) => ({
            ...prevForm,
            [name]: type === 'checkbox' ? checked : value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSaving(true);
        setErrorMessage('');

        const artistDto = {
            artistname: form.artistname,
            description: form.description,
            tiktokUrl: form.tiktokUrl,
            instagramUrl: form.instagramUrl,
            facebookUrl: form.facebookUrl,
            spotifyId: form.spotifyId,
            id: form.id,
            artistExists: form.artistExists,
            invoiceName: form.invoiceName,
            invoiceStreet: form.invoiceStreet,
            invoiceTown: form.invoiceTown,
            invoiceCountry: form.invoiceCountry,
            invoiceZipCode: form.invoiceZipCode,
            iban: form.iban,
        };

        const formData = new FormData();
        formData.append('artistDto', JSON.stringify(artistDto));
        if (form.image) {
            formData.append('image', form.image);
        }

        try {
            const response = await ArtistService.createArtist(formData);
            navigate(`/artists/${response.data.id}`);
        } catch (error) {
            console.error('Error submitting artist:', error);
            setErrorMessage(error.response?.data?.error || 'An error occurred. Please try again.');
        } finally {
            setIsSaving(false);
        }
    };

    return (
        <div className="min-h-screen bg-gray-100">
            <Header isLoggedIn={true} logoutUser={() => console.log('Logged out')} />
            <div className="container mx-auto p-6">
                <h2 className="text-3xl font-bold text-gray-800 mb-6">{editMode ? 'Edit Artist' : 'Create Artist'}</h2>
                <form className="space-y-8 bg-white p-6 rounded-lg shadow-md" onSubmit={handleSubmit}>

                    {errorMessage && (
                        <div className="mb-4 text-red-600">
                            {errorMessage}
                        </div>
                    )}

                    <div>
                        <label className="block text-sm font-medium text-gray-700 mb-2">Artist Name</label>
                        <input
                            type="text"
                            name="artistname"
                            value={form.artistname}
                            onChange={handleChange}
                            className="block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                            placeholder="Enter artist name"
                        />
                    </div>

                    <div>
                        <label className="block text-sm font-medium text-gray-700 mb-2">Description</label>
                        <textarea
                            name="description"
                            value={form.description}
                            onChange={handleChange}
                            className="block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                            placeholder="Enter artist description"
                        />
                    </div>

                    {!editMode && (
                        <div className="flex items-start mb-4">
                            <input
                                type="checkbox"
                                name="artistExists"
                                checked={form.artistExists}
                                onChange={handleChange}
                                className="h-4 w-4 text-blue-600 border-gray-300 rounded"
                            />
                            <label className="ml-3 block text-sm font-medium text-gray-700">Artist already exists</label>
                        </div>
                    )}

                    {!editMode && form.artistExists && (
                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-2">Find Artist</label>
                            <input
                                type="text"
                                name="artistSearch"
                                value={form.artistSearch}
                                onChange={handleArtistSearch}
                                className="block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                                placeholder="Search for artist on Spotify"
                            />
                            {loadingArtists && (
                                <div className="mt-2 text-blue-600">Searching...</div>
                            )}
                            {showArtistDropdown && (
                                <ul className="mt-2 border border-gray-300 rounded-md shadow-md bg-white max-h-60 overflow-y-auto">
                                    {filteredArtists.length > 0 ? (
                                        filteredArtists.map((artist) => (
                                            <li
                                                key={artist.id}
                                                onClick={() => handleArtistSelect(artist)}
                                                className="cursor-pointer hover:bg-gray-100 p-2 rounded flex items-center"
                                            >
                                                <img
                                                    src={artist.images[0]?.url}
                                                    alt={artist.name}
                                                    className="w-10 h-10 mr-2 rounded-full object-cover"
                                                />
                                                <div>
                                                    <p className="font-medium">{artist.name}</p>
                                                    <p className="text-sm text-gray-500">Followers: {artist.followers.total}</p>
                                                </div>
                                            </li>
                                        ))
                                    ) : (
                                        <li className="p-2 text-gray-500">No artists found.</li>
                                    )}
                                </ul>
                            )}
                        </div>
                    )}

                    {!editMode && !form.artistExists && (
                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-2">Spotify ID</label>
                            <input
                                type="text"
                                name="spotifyId"
                                value={form.spotifyId}
                                onChange={handleChange}
                                className="block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                                placeholder="Enter Spotify ID"
                            />
                        </div>
                    )}

                    <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-2">Instagram (optional)</label>
                            <input
                                type="text"
                                name="instagramUrl"
                                value={form.instagramUrl}
                                onChange={handleChange}
                                className="block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                                placeholder="Enter Instagram URL"
                            />
                        </div>
                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-2">Facebook (optional)</label>
                            <input
                                type="text"
                                name="facebookUrl"
                                value={form.facebookUrl}
                                onChange={handleChange}
                                className="block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                                placeholder="Enter Facebook URL"
                            />
                        </div>
                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-2">TikTok (optional)</label>
                            <input
                                type="text"
                                name="tiktokUrl"
                                value={form.tiktokUrl}
                                onChange={handleChange}
                                className="block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                                placeholder="Enter TikTok URL"
                            />
                        </div>
                    </div>

                    <div>
                        <label className="block text-sm font-medium text-gray-700 mb-2">Invoice Name (optional)</label>
                        <input
                            type="text"
                            name="invoiceName"
                            value={form.invoiceName}
                            onChange={handleChange}
                            className="block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                            placeholder="Enter invoice name"
                        />
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-2">Invoice Street (optional)</label>
                            <input
                                type="text"
                                name="invoiceStreet"
                                value={form.invoiceStreet}
                                onChange={handleChange}
                                className="block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                                placeholder="Enter invoice street"
                            />
                        </div>
                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-2">Invoice Town (optional)</label>
                            <input
                                type="text"
                                name="invoiceTown"
                                value={form.invoiceTown}
                                onChange={handleChange}
                                className="block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                                placeholder="Enter invoice town"
                            />
                        </div>
                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-2">Invoice Zip Code (optional)</label>
                            <input
                                type="text"
                                name="invoiceZipCode"
                                value={form.invoiceZipCode}
                                onChange={handleChange}
                                className="block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                                placeholder="Enter invoice zip code"
                            />
                        </div>
                    </div>

                    <div>
                        <label className="block text-sm font-medium text-gray-700 mb-2">Invoice Country (optional)</label>
                        <Select
                            isMulti={false}
                            options={countryOptions}
                            placeholder={"Select country"}
                            value={countryOptions.find(option => option.value === form.invoiceCountry) || null} // Show null when no selection
                            onChange={(selectedOption) => setForm({ ...form, invoiceCountry: selectedOption ? selectedOption.value : '' })} // Update or clear selection
                            isClearable={true} // Allow clearing the selected country
                            className="basic-select"
                            classNamePrefix="select"
                        />
                    </div>

                    <div>
                        <label className="block text-sm font-medium text-gray-700 mb-2">IBAN (optional)</label>
                        <input
                            type="text"
                            name="iban"
                            value={form.iban}
                            onChange={handleChange}
                            className="block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                            placeholder="Enter IBAN"
                        />
                    </div>

                    <div className="flex justify-end">
                        <button
                            type="submit"
                            className={`bg-blue-500 text-white px-6 py-2 rounded-md shadow-md ${isSaving ? 'opacity-50 cursor-not-allowed' : 'hover:bg-blue-600'} focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50`}
                            disabled={isSaving}
                        >
                            {isSaving ? 'Saving...' : editMode ? 'Update Artist' : 'Save Artist'}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default ArtistCreate;
