import axiosInstance from './axiosInstance';

class BillingService {

    // Fetch invoices for the selected artist
    getInvoicesForArtist(artistId) {
        return axiosInstance.get(`/invoices/artist/${artistId}`);
    }

    // Fetch payout requests for the selected artist
    getPayoutRequestsForArtist(artistId) {
        return axiosInstance.get(`/invoices/payoutRequests/${artistId}`);
    }

    // Fetch payout saldo for the selected artist
    getPayoutSaldoForArtist(artistId) {
        return axiosInstance.get(`/invoices/payoutInformation/${artistId}`);
    }

    // Create a new payout request for the selected artist
    createPayoutRequest(artistId) {
        return axiosInstance.post(`/invoices/payoutRequest/${artistId}`);
    }

    // Download an invoice by its ID
    downloadInvoice(id) {
        return axiosInstance.get(`/invoices/${id}`, { responseType: 'blob' });
    }

    // Download a payout request invoice by its ID
    downloadPayoutRequestInvoice(id) {
        return axiosInstance.get(`/invoices/payoutRequest/pdf/${id}`, { responseType: 'blob' });
    }

    // Set a payout request as "paid out"
    setPaidOut(payoutRequestId) {
        return axiosInstance.post(`/invoices/payoutRequests/paidOut/${payoutRequestId}`);
    }

    // Fetch all payout requests (for admin use)
    getAllPayoutRequests() {
        return axiosInstance.get(`/invoices/payoutRequests/all`);
    }
}

export default new BillingService();
