// src/components/SavePage/SavePage.jsx

import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import CampaignService from '../../services/CampaignService';
import AnalyticsService from '../../services/AnalyticsService';
import MusicKitService from '../../services/MusicKitService';
import Header from '../../core/Header';
import Globals from '../../core/globals';
import { FaSpotify, FaApple, FaHeart, FaTiktok, FaInstagram, FaFacebook, FaDownload, FaTimes } from 'react-icons/fa';
import Footer from "../../core/Footer";

const SavePage = () => {
    const { artistname, campaignname } = useParams();
    const navigate = useNavigate();
    const [campaign, setCampaign] = useState(null);
    const [consentGiven, setConsentGiven] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [userEmail, setUserEmail] = useState('');
    const [appleMusicKitInstance, setAppleMusicKitInstance] = useState(null);
    const [appleMusicToken, setAppleMusicToken] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const identifier = `/${artistname}/${campaignname}`;

    useEffect(() => {
        const fetchCampaign = async () => {
            try {
                setLoading(true);
                await AnalyticsService.countCampaignClick(identifier, `${Globals.frontendUri}${identifier}`);
                const response = await CampaignService.getCampaignByUrlSuffix(identifier);
                setCampaign(response.data);
            } catch (error) {
                setError('Something went wrong loading the campaign.');
                console.error('Error loading campaign:', error);
            } finally {
                setLoading(false);
            }
        };
        fetchCampaign();
    }, [identifier]);

    const executePreSave = () => {
        window.open(campaign.redirectUrl, '_blank');
    };

    const executePreSaveApple = async () => {
        try {
            setLoading(true);
            const instance = MusicKitService.configureMusicKit(appleMusicToken);
            setAppleMusicKitInstance(instance);
            await instance.authorize();
            await MusicKitService.createAppleMusicPreSave(campaign.id, instance.musicUserToken, userEmail);
            setShowModal(false);

            // Correctly navigate with query parameters
            navigate(`/success?success=true&campaign_id=${encodeURIComponent(identifier)}`);
        } catch (error) {
            console.error('Error performing Apple Music Pre-Save:', error);
            setError('Failed to pre-save on Apple Music.');
        } finally {
            setLoading(false);
        }
    };

    const streamService = (url, serviceName) => {
        AnalyticsService.countCampaignClick(identifier, url);
        window.open(url, '_blank');
    };

    const toggleModal = () => {
        setShowModal(true);
        MusicKitService.getAuthToken()
            .then((response) => {
                setAppleMusicToken(response.data.token);
            })
            .catch((error) => {
                console.error('Error fetching Apple Music token:', error);
                setError('Failed to retrieve Apple Music token.');
            });
    };

    const getArtistNamesFormatted = () => {
        if (!campaign) return '';
        const artists = campaign.featureArtistNames
        if (artists.length === 0) {
            return campaign.artistName;
        } else if (artists.length === 1) {
            return `${campaign.artistName} and ${artists[0]}`;
        } else {
            const lastArtist = artists.pop();
            return `${campaign.artistName}, ${artists.join(', ')}, and ${lastArtist}`;
        }
    };

    if (loading) {
        return (
            <div className="flex items-center justify-center min-h-screen bg-gray-50">
                <div className="loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-16 w-16"></div>
            </div>
        );
    }

    if (error) {
        return (
            <div className="flex flex-col items-center justify-center min-h-screen bg-gray-50">
                <h1 className="text-2xl font-semibold text-red-600 mb-4">{error}</h1>
                <button
                    onClick={() => window.location.reload()}
                    className="px-6 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition duration-300"
                >
                    Retry
                </button>
            </div>
        );
    }

    return (

        <div className="min-h-screen flex flex-col bg-gradient-to-b from-gray-50 to-gray-200">
            <Header showNavbar={false} />

            <main className="flex-grow flex items-center justify-center px-4 py-8">
                {campaign && (
                    <div className="bg-white rounded-xl shadow-lg max-w-xl w-full p-6 text-center">
                        <img
                            src={campaign.artworkUrl}
                            alt="Campaign Artwork"
                            className="w-full h-auto rounded-lg mb-6 shadow-md"
                        />
                        <h1 className="text-3xl font-bold text-gray-800 mb-2">{campaign.campaignTitle}</h1>
                        <h3 className="text-xl font-medium text-gray-600 mb-6">{getArtistNamesFormatted()}</h3>

                        {!campaign.isReleased ? (
                            <>
                                <div className="flex flex-col space-y-4 mb-6">
                                    <button
                                        onClick={executePreSave}
                                        disabled={!consentGiven}
                                        className={`flex items-center justify-center px-6 py-3 rounded-md text-white font-semibold transition duration-300 ${
                                            consentGiven
                                                ? 'bg-green-500 hover:bg-green-600'
                                                : 'bg-green-300 cursor-not-allowed'
                                        }`}
                                    >
                                        <FaSpotify className="mr-2 text-2xl" />
                                        Pre-Save on Spotify
                                    </button>
                                    <button
                                        onClick={toggleModal}
                                        disabled={!consentGiven}
                                        className={`flex items-center justify-center px-6 py-3 rounded-md text-white font-semibold transition duration-300 ${
                                            consentGiven
                                                ? 'bg-black hover:bg-gray-800'
                                                : 'bg-gray-500 cursor-not-allowed'
                                        }`}
                                    >
                                        <FaApple className="mr-2 text-2xl" />
                                        Pre-Save on Apple Music
                                    </button>
                                </div>
                                <div className="flex items-center justify-center mb-6">
                                    <input
                                        type="checkbox"
                                        id="consentCheckbox"
                                        checked={consentGiven}
                                        onChange={() => setConsentGiven(!consentGiven)}
                                        className="w-5 h-5 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
                                    />
                                    <label htmlFor="consentCheckbox" className="ml-2 text-gray-600 text-sm">
                                        I agree to share data with <span className="font-medium">{campaign.artistName}</span> and have viewed their privacy policy.*
                                    </label>
                                </div>
                            </>
                        ) : (
                            <div className="flex flex-col space-y-4 mb-6">
                                {campaign.spotifyUrl && (
                                    <button
                                        onClick={() => streamService(campaign.spotifyUrl, 'Spotify')}
                                        className="flex items-center justify-center px-6 py-3 bg-green-500 hover:bg-green-600 text-white rounded-md font-semibold transition duration-300"
                                    >
                                        <FaSpotify className="mr-2 text-2xl" />
                                        Listen on Spotify
                                    </button>
                                )}
                                {campaign.appleMusicUrl && (
                                    <button
                                        onClick={() => streamService(campaign.appleMusicUrl, 'Apple Music')}
                                        className="flex items-center justify-center px-6 py-3 bg-black hover:bg-gray-800 text-white rounded-md font-semibold transition duration-300"
                                    >
                                        <FaApple className="mr-2 text-2xl" />
                                        Listen on Apple Music
                                    </button>
                                )}
                                {campaign.deezerUrl && (
                                    <button
                                        onClick={() => streamService(campaign.deezerUrl, 'Deezer')}
                                        className="flex items-center justify-center px-6 py-3 bg-pink-500 hover:bg-pink-600 text-white rounded-md font-semibold transition duration-300"
                                    >
                                        <FaHeart className="mr-2 text-2xl" />
                                        Listen on Deezer
                                    </button>
                                )}
                                {campaign.teaserUrl && (
                                    <button
                                        onClick={() => streamService(campaign.teaserUrl, 'Download Teaser')}
                                        className="flex items-center justify-center px-6 py-3 bg-blue-500 hover:bg-blue-600 text-white rounded-md font-semibold transition duration-300"
                                    >
                                        <FaDownload className="mr-2 text-2xl" />
                                        Download Teaser
                                    </button>
                                )}
                            </div>
                        )}

                        <div className="flex items-center justify-center space-x-6 mt-4">
                            {campaign.tiktokUrl && campaign.tiktokUrl != "https://www.tiktok.com/" && (
                                <a
                                    href={campaign.tiktokUrl}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="text-gray-600 hover:text-gray-800 transition duration-300 text-2xl"
                                >
                                    <FaTiktok />
                                </a>
                            )}
                            {campaign.instagramUrl && campaign.instagramUrl != "https://www.instagram.com/" && (
                                <a
                                    href={campaign.instagramUrl}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="text-gray-600 hover:text-gray-800 transition duration-300 text-2xl"
                                >
                                    <FaInstagram />
                                </a>
                            )}
                            {campaign.facebookUrl && campaign.facebookUrl != "https://www.facebook.com/" && (
                                <a
                                    href={campaign.facebookUrl}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="text-gray-600 hover:text-gray-800 transition duration-300 text-2xl"
                                >
                                    <FaFacebook />
                                </a>
                            )}
                        </div>
                    </div>
                )}

            </main>

            {/* Apple Music Pre-Save Modal */}
            {showModal && (
                <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
                    <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-md relative">
                        <button
                            onClick={() => setShowModal(false)}
                            className="absolute top-3 right-3 text-gray-600 hover:text-gray-800 transition duration-300"
                        >
                            <FaTimes size={20} />
                        </button>
                        <h2 className="text-2xl font-semibold text-gray-800 mb-4">Pre-Save on Apple Music</h2>
                        <p className="text-gray-600 mb-4">Enter your email to pre-save this campaign on Apple Music.</p>
                        <input
                            type="email"
                            value={userEmail}
                            onChange={(e) => setUserEmail(e.target.value)}
                            placeholder="Your Email"
                            className="w-full px-4 py-2 border border-gray-300 rounded-md mb-4 focus:outline-none focus:border-blue-500"
                        />
                        <button
                            onClick={executePreSaveApple}
                            disabled={!userEmail}
                            className={`w-full px-4 py-2 rounded-md text-white font-semibold transition duration-300 ${
                                userEmail
                                    ? 'bg-black hover:bg-gray-800'
                                    : 'bg-gray-500 cursor-not-allowed'
                            }`}
                        >
                            {loading ? 'Processing...' : 'Confirm Pre-Save'}
                        </button>
                        {error && (
                            <p className="text-red-500 text-sm mt-2">{error}</p>
                        )}
                    </div>


                </div>
            )}
            <Footer />

        </div>

    );
};

export default SavePage;
