import React, { useState } from 'react';
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import Modal from 'react-modal';

function PaymentModal({ onClose }) {
    const stripe = useStripe();
    const elements = useElements();
    const [paymentProcessing, setPaymentProcessing] = useState(false);
    const [error, setError] = useState('');

    const handleStripePayment = async () => {
        if (!stripe || !elements) {
            console.log("Stripe or Elements not initialized.");
            return;
        }

        setPaymentProcessing(true);

        try {
            const result = await stripe.confirmPayment({
                elements,
                confirmParams: {
                    return_url: `${window.location.origin}/event/payment/success`,
                },
            });

            if (result.error) {
                setError(`Payment failed: ${result.error.message}`);
                setPaymentProcessing(false);
            } else {
                console.log("Payment succeeded");
                window.location.href = `${window.location.origin}/event/payment/success`;
            }
        } catch (err) {
            console.error(err);
            setError('Payment failed. Please try again.');
            setPaymentProcessing(false);
        }
    };

    return (
        <Modal
            isOpen={true}
            onRequestClose={onClose}
            contentLabel="Stripe Payment"
            className="max-w-lg p-8 bg-white rounded-lg shadow-lg mx-auto mt-12"
            overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center"
        >
            <h2 className="text-xl font-bold mb-4">Complete Payment</h2>
            {error && (
                <div className="bg-red-100 border mt-2 border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
                    <span className="block sm:inline">{error}</span>
                </div>
            )}
            <div id="payment-element" className="p-4 bg-gray-100 border rounded-md">
                <PaymentElement />
            </div>
            <div className="mt-6">
                <button
                    onClick={handleStripePayment}
                    className="w-full py-2 rounded-md bg-blue-600 text-white font-semibold hover:bg-blue-700 transition duration-200"
                    disabled={paymentProcessing}
                >
                    {paymentProcessing ? 'Processing...' : 'Confirm Payment'}
                </button>
            </div>
            <button
                onClick={onClose}
                className="mt-4 w-full py-2 rounded-md bg-gray-500 text-white hover:bg-gray-600 transition duration-200"
            >
                Cancel
            </button>
        </Modal>
    );
}

export default PaymentModal;
