import axiosInstance from './axiosInstance'; // Assuming you're using an Axios instance for API requests
import {jwtDecode} from 'jwt-decode';

class AuthService {
    constructor() {
        this.authBaseUri = '/authentication/'
        this.baseUri = '';
    }

    // Login user and set token in localStorage
    async loginUser(username, password) {
        try {
            const response = await axiosInstance.post(this.authBaseUri, {
                email: username,
                password: password,
            }, {
                responseType: 'text',
            });
            this.setToken(response.data);  // Store the token
            return response.data;  // Return the token
        } catch (error) {
            throw new Error(error.response?.data || 'Login failed');
        }
    }

    // Register user and set token in localStorage
    async register(user) {
        try {
            const response = await axiosInstance.post(`${this.baseUri}/users/register`, user, {
                responseType: 'text',
            });
            this.setToken(response.data);  // Store the token
            return response.data;  // Return the token
        } catch (error) {
            throw new Error(error.response?.data || 'Registration failed');
        }
    }


    requestPasswordReset(email) {
        return axiosInstance.get(`${this.baseUri}/users/reset?email=${encodeURIComponent(email)}`);
    }

    resetPassword(data) {
        return axiosInstance.post(`${this.baseUri}/users/reset`, data);
    }


    // Get user information (e.g., email)
    async getUserInformation() {
        try {
            const response = await axiosInstance.get(`${this.baseUri}/users/email`);
            return response.data;
        } catch (error) {
            throw new Error(error.response?.data || 'Failed to retrieve user information');
        }
    }

    // Check if user is logged in based on token presence and expiration
    isLoggedIn() {
        const token = this.getToken();
        return !!token && this.getTokenExpirationDate(token) > new Date();
    }

    // Logout user (remove token from localStorage)
    logoutUser() {
        localStorage.removeItem('authToken');
    }

    // Get the stored token from localStorage
    getToken() {
        return localStorage.getItem('authToken') || '';
    }

    // Get user role based on the current token
    getUserRole() {
        const token = this.getToken();
        if (token) {
            const decoded = jwtDecode(token);
            const roles = decoded.rol || [];
            if (roles.includes('ROLE_ADMIN')) {
                return 'ADMIN';
            } else if (roles.includes('ROLE_LABEL')) {
                return 'LABEL';
            } else if (roles.includes('ROLE_USER')) {
                return 'USER';
            }
        }
        return 'UNDEFINED';
    }

    // Get user email based on the current token
    getEmail() {
        const token = this.getToken();
        if (token) {
            const decoded = jwtDecode(token);
            return decoded.sub || 'UNDEFINED';
        }
        return 'UNDEFINED';
    }

    // Set token in localStorage
    setToken(token) {
        localStorage.setItem('authToken', token);
    }


    // Get token expiration date
    getTokenExpirationDate(token) {
        const decoded = jwtDecode(token);
        if (!decoded.exp) {
            return null;
        }
        const expirationDate = new Date(0);
        expirationDate.setUTCSeconds(decoded.exp);
        return expirationDate;
    }
}

export default new AuthService();
