import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ArtistService from '../../services/ArtistService';
import AnalyticsService from '../../services/AnalyticsService';
import Header from '../../core/Header';
import Footer from '../../core/Footer';
import {
    FaSpotify,
    FaApple,
    FaFacebook,
    FaInstagram,
    FaTwitter,
    FaYoutube,
    FaTiktok,
    FaSoundcloud,
    FaHeart, FaClock, FaCalendarAlt, FaArchive // Deezer icon
} from 'react-icons/fa';

const linkIconMap = {
    spotifyLink: { icon: <FaSpotify />, label: 'Spotify', colorClass: 'bg-green-500 hover:bg-green-600' },
    appleMusicLink: { icon: <FaApple />, label: 'Apple Music', colorClass: 'bg-black hover:bg-gray-800' },
    deezerLink: { icon: <FaHeart />, label: 'Deezer', colorClass: 'bg-pink-500 hover:bg-pink-600' },
    soundCloudLink: { icon: <FaSoundcloud />, label: 'SoundCloud', colorClass: 'bg-orange-500 hover:bg-orange-600' },
    facebookLink: { icon: <FaFacebook />, label: 'Facebook', colorClass: 'bg-blue-600 hover:bg-blue-700' },
    instagramLink: { icon: <FaInstagram />, label: 'Instagram', colorClass: 'bg-pink-500 hover:bg-pink-600' },
    twitterLink: { icon: <FaTwitter />, label: 'Twitter', colorClass: 'bg-blue-400 hover:bg-blue-500' },
    youtubeLink: { icon: <FaYoutube />, label: 'YouTube', colorClass: 'bg-red-600 hover:bg-red-700' },
    tikTokLink: { icon: <FaTiktok />, label: 'TikTok', colorClass: 'bg-black hover:bg-gray-800' },
};

const ArtistLinkLanding = () => {
    const { identifier } = useParams();
    const [artistLink, setArtistLink] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    useEffect(() => {
        const fetchArtistLink = async () => {
            try {
                const response = await ArtistService.getArtistLinkByIdentifier(identifier);
                setArtistLink(response.data);
                AnalyticsService.countLinkClick(identifier, `https://labelboost.net/${identifier}`);
            } catch (error) {
                setError('Failed to load artist data.');
            } finally {
                setLoading(false);
            }
        };
        fetchArtistLink();
    }, [identifier]);

    const linkClicked = (url) => {
        AnalyticsService.countLinkClick(identifier, url);
        window.open(url, '_blank');
    };

    if (loading) {
        return (
            <div className="flex items-center justify-center min-h-screen bg-gray-50">
                <div className="loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-16 w-16"></div>
            </div>
        );
    }

    if (error) {
        return (
            <div className="flex items-center justify-center min-h-screen bg-gray-50">
                <h1 className="text-2xl font-semibold text-red-600 mb-4">{error}</h1>
            </div>
        );
    }

    return (
        <div className="min-h-screen flex flex-col bg-gray-100">
            <Header showNavbar={false} />
            <main className="flex-grow flex items-center justify-center px-4 py-8">
                {artistLink && (
                    <div className="bg-white rounded-lg shadow-lg max-w-xl w-full p-6 text-center">
                        <img
                            src={artistLink.imageUrl}
                            alt="Artist Image"
                            className="w-full h-auto rounded-lg mb-4 shadow-md"
                        />
                        <p className="text-xl font-semibold text-gray-800 mb-4">@{artistLink.identifier}</p>

                        {/* Social Media & Streaming Links */}
                        <div className="flex flex-col gap-4 mb-6">
                            {(artistLink.order || []).map((key) => {
                                // Handle campaigns and events based on boolean flags and data availability
                                if (key === 'upcomingCampaigns' && artistLink.showUpcomingCampaigns && artistLink.upcomingCampaigns?.length > 0) {
                                    return artistLink.upcomingCampaigns.map((campaign) => (
                                        <button
                                            key={campaign.url}
                                            onClick={() => linkClicked(campaign.url)}
                                            className="flex items-center justify-center w-full bg-green-500 text-white px-6 py-3 rounded-md hover:bg-green-600 transition-colors"
                                            style={{ fontSize: '1.25rem', gap: '0.5rem' }}
                                        >
                                            <FaClock className="mr-2" /> Pre-Save {campaign.name}
                                        </button>
                                    ));
                                }
                                if (key === 'previousCampaigns' && artistLink.showPreviousCampaigns && artistLink.previousCampaigns?.length > 0) {
                                    return artistLink.previousCampaigns.map((campaign) => (
                                        <button
                                            key={campaign.url}
                                            onClick={() => linkClicked(campaign.url)}
                                            className="flex items-center justify-center w-full bg-blue-500 text-white px-6 py-3 rounded-md hover:bg-blue-600 transition-colors"
                                            style={{ fontSize: '1.25rem', gap: '0.5rem' }}
                                        >
                                            <FaArchive className="mr-2" /> Stream {campaign.name}
                                        </button>
                                    ));
                                }
                                if (key === 'upcomingEvents' && artistLink.showUpcomingEvents && artistLink.upcomingEvents?.length > 0) {
                                    return artistLink.upcomingEvents.map((event) => (
                                        <button
                                            key={event.url}
                                            onClick={() => linkClicked(event.url)}
                                            className="flex items-center justify-center w-full bg-red-500 text-white px-6 py-3 rounded-md hover:bg-red-600 transition-colors"
                                            style={{ fontSize: '1.25rem', gap: '0.5rem' }}
                                        >
                                            <FaCalendarAlt className="mr-2" /> Tickets for {event.name}
                                        </button>
                                    ));
                                }

                                // Handle regular links based on availability
                                const linkData = linkIconMap[key];
                                if (artistLink[key] && linkData) {
                                    return (
                                        <button
                                            key={key}
                                            onClick={() => linkClicked(artistLink[key])}
                                            className={`flex items-center justify-center ${linkData.colorClass} text-white px-6 py-3 rounded-md transition-colors`}
                                            style={{ fontSize: '1.25rem', gap: '0.5rem' }}
                                        >
                                            {linkData.icon} {linkData.label}
                                        </button>
                                    );
                                }
                                return null;
                            })}
                        </div>
                    </div>
                )}
            </main>
            <Footer />
        </div>
    );
};

export default ArtistLinkLanding;
