import React, { useState, useEffect } from 'react';
import Header from '../../core/Header';
import Globals from "../../core/globals";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import {FaPlus} from "react-icons/fa";

function CampaignsOverview() {
  const [campaigns, setCampaigns] = useState([]);
  const [loadingCampaigns, setLoadingCampaigns] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCampaigns = async () => {
      try {
        const token = localStorage.getItem('authToken'); // Assuming you're storing the token in localStorage
        const response = await axios.get(`${Globals.backendUri}/campaigns/all`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        // Set campaigns with the data fetched from the backend
        setCampaigns(response.data.sort((a, b) => new Date(b.releaseDate) - new Date(a.releaseDate)));
      } catch (error) {
        console.error("Error fetching campaigns:", error);
      } finally {
        setLoadingCampaigns(false);
      }
    };

    fetchCampaigns();
  }, []);

  const upcomingCampaigns = campaigns.filter(campaign => new Date(campaign.releaseDate) > new Date());
  const releasedCampaigns = campaigns.filter(campaign => new Date(campaign.releaseDate) <= new Date());

  const noCampaigns = upcomingCampaigns.length === 0 && releasedCampaigns.length === 0;

  const navigateToCampaignDetail = (id) => {
    navigate(`/campaigns/${id}`);
  };

  return (
      <div className="min-h-screen bg-gray-100">
        <Header isLoggedIn={true} logoutUser={() => console.log("Logged out")} />
        <div className="container mx-auto p-6">
          <div className="flex justify-between items-center mb-6">
            <h1 className="text-3xl font-bold text-gray-800">Your Campaigns</h1>
            <button
                className="bg-blue-500 text-white px-4 py-2 rounded-md shadow-md hover:bg-blue-600 flex items-center"
                onClick={() => navigate('/campaigns/create')}
            >
              <FaPlus className="mr-2"/> Campaign
            </button>

          </div>

          {loadingCampaigns ? (
              <div className="text-center">
                <span className="spinner-border spinner-border-sm mr-2"></span> Loading Campaigns
              </div>
          ) : (
              <>
                {noCampaigns ? (
                    <div className="text-center">
                      <h3>No campaigns yet</h3>
                    </div>
                ) : (
                    <div>
                      <div>
                        <h3 className="text-2xl font-semibold mt-6 mb-4">Upcoming Campaigns</h3>
                        {upcomingCampaigns.length === 0 ? (
                            <p>There are no upcoming campaigns</p>
                        ) : (
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                              {upcomingCampaigns.map(campaign => (
                                  <div
                                      key={campaign.id}
                                      className="block border rounded-lg p-4 mb-4 shadow-sm hover:shadow-lg transition-shadow duration-200 flex items-center justify-between cursor-pointer"
                                      onClick={() => navigateToCampaignDetail(campaign.id)}
                                  >
                                    <div className="flex items-center">
                                      <img
                                          src={campaign.artworkUrl}
                                          alt={campaign.name}
                                          className="h-24 w-24 mr-4 object-cover rounded"
                                      />
                                      <div>
                                        <h4 className="text-xl font-bold text-gray-800">{campaign.name}</h4>
                                        <span className="text-gray-500">({campaign.artistName})</span>
                                      </div>
                                    </div>
                                  </div>
                              ))}
                            </div>
                        )}
                      </div>

                      <div>
                        <h3 className="text-2xl font-semibold mt-6 mb-4">Released Campaigns</h3>
                        {releasedCampaigns.length === 0 ? (
                            <p>There are no released campaigns</p>
                        ) : (
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                              {releasedCampaigns.map(campaign => (
                                  <div
                                      key={campaign.id}
                                      className="block border rounded-lg p-4 mb-4 shadow-sm hover:shadow-lg transition-shadow duration-200 flex items-center justify-between cursor-pointer"
                                      onClick={() => navigateToCampaignDetail(campaign.id)}
                                  >
                                    <div className="flex items-center">
                                      <img
                                          src={campaign.artworkUrl}
                                          alt={campaign.name}
                                          className="h-24 w-24 mr-4 object-cover rounded"
                                      />
                                      <div>
                                        <h4 className="text-xl font-bold text-gray-800">{campaign.name}</h4>
                                        <span className="text-gray-500">({campaign.artistName})</span>
                                      </div>
                                    </div>
                                  </div>
                              ))}
                            </div>
                        )}
                      </div>
                    </div>
                )}
              </>
          )}
        </div>
      </div>
  );
}

export default CampaignsOverview;
