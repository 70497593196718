import axios from 'axios';
import Globals from '../core/globals';

class MusicKitService {
    constructor() {
        this.baseUri = `${Globals.backendUri}/campaigns/`;
        this.musicKit = null;
    }

    // Method to get the developer token from your backend
    getAuthToken() {
        return axios.get(`${this.baseUri}apple/token`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('authToken')}`,
            },
        });
    }

    // Method to configure MusicKit using the token from the backend
    configureMusicKit(token) {
        if (!window.MusicKit) {
            throw new Error('MusicKit is not loaded. Make sure the MusicKit script is included in your HTML.');
        }

        const conf = window.MusicKit.configure({
            developerToken: token,
            app: {
                name: 'LabelBoost',
            },
            declarativeMarkup: true
        });

        console.log(conf);

        this.musicKit = window.MusicKit.getInstance();
        return this.musicKit;
    }

    // Method to create an Apple Music pre-save
    createAppleMusicPreSave(campaignId, musicUserToken, userMail) {
        return axios.post(`${this.baseUri}apple/presave`, {
            campaignId,
            musicUserToken,
            userMail,
        }, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('authToken')}`,
            },
        });
    }
}

export default new MusicKitService();
