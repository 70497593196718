import React from 'react';
import { Link } from 'react-router-dom';

function Footer() {
    return (
        <div className="max-w-2xl mx-auto bg-white p-6 rounded-lg shadow-lg text-center mb-4">
            <div className="flex justify-center space-x-4">
                <Link to="/imprint" className="text-blue-500 hover:text-blue-700">
                    IMPRINT
                </Link>
                <Link to="/privacy" className="text-blue-500 hover:text-blue-700">
                    PRIVACY
                </Link>
            </div>
        </div>
    );
}

export default Footer;
