import axiosInstance from './axiosInstance';
import Globals from '../core/globals';
import axios from "axios";

const axiosInstanceNoToken = axios.create({
    baseURL: Globals.backendUri,
});


class EventService {
    constructor() {
        this.baseUri = `${Globals.backendUri}/events/`;
    }

    getAllEvents() {
        return axiosInstance.get(this.baseUri);
    }

    getEventById(id) {
        return axiosInstance.get(`${this.baseUri}${id}`);
    }

    deleteEvent(id) {
        return axiosInstance.delete(`${this.baseUri}${id}`);
    }

    createEvent(eventData) {
        return axiosInstance.post(this.baseUri, eventData);
    }

    updateEvent(eventData) {
        return axiosInstance.put(this.baseUri, eventData);
    }

    checkoutEvent(checkoutDto) {
        return axiosInstanceNoToken.post(`${this.baseUri}checkout`, checkoutDto);
    }

    checkoutCard(checkoutDto) {
        return axiosInstanceNoToken.post(`${this.baseUri}checkout/card`, checkoutDto);
    }

    confirmPaypalPayment(id, paymentId, payerID) {
        return axiosInstance.post(`${this.baseUri}checkout/paypal/success`, {
            id,
            paymentId,
            payerID
        });
    }

    confirmEvent(id) {
        return axiosInstance.get(`${this.baseUri}confirm/${id}`);
    }

    getParticipantsList(id) {
        return axiosInstance.get(`${this.baseUri}participants/list/${id}`, {
            responseType: 'blob'
        });
    }

    getEventLanding(id) {
        return axiosInstanceNoToken.get(`${this.baseUri}landing/${id}`);
    }

    deleteCategory(id) {
        return axiosInstance.delete(`${this.baseUri}categories/${id}`);
    }

    // New methods for the check-in functionality

    /**
     * Fetch check-in data for the event, including tickets and their scanned status.
     * @param {string} eventId
     * @returns {Promise} Axios response
     */
    getEventCheckIn(eventId) {
        return axiosInstance.get(`${this.baseUri}scan/${eventId}`);
    }

    /**
     * Submit the list of modified tickets (e.g., marked as scanned) to the backend.
     * @param {Array} tickets - List of ticket objects with changed status.
     * @returns {Promise} Axios response
     */
    submitChangedTickets(tickets) {
        return axiosInstance.post(`${this.baseUri}scan`, { tickets });
    }

    /**
     * Submit a single scanned ticket based on the ticket ID and event ID.
     * @param {string} ticketId - The ID of the scanned ticket.
     * @param {string} eventId - The ID of the event.
     * @returns {Promise} Axios response
     */
    scanTicket(ticketId, eventId) {
        return axiosInstance.post(`${this.baseUri}scan/ticket`, { ticketId, eventId });
    }
}

export default new EventService();
