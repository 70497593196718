import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, PaymentElement } from '@stripe/react-stripe-js';
import Modal from 'react-modal';
import EventService from '../../services/EventService';
import globals from "../../core/globals";
import PaymentModal from "../../core/PaymentModal";
import Footer from "../../core/Footer";
import Header from "../../core/Header";
const stripePromise = loadStripe(globals.findStripeKey()); // Use your actual Stripe publishable key

Modal.setAppElement('#root');

function EventSell() {
    const { id } = useParams();

    const [event, setEvent] = useState(null);
    const [loading, setLoading] = useState(true);
    const [categories, setCategories] = useState([]);
    const [totalPrice, setTotalPrice] = useState(0);
    const [formValues, setFormValues] = useState({
        name: '',
        mail: '',
        categories: [],
        selectedPaymentType: '',
    });
    const [paymentProcessing, setPaymentProcessing] = useState(false);
    const [paymentType, setPaymentType] = useState('');
    const [paymentTypes, setPaymentTypes] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [clientSecret, setClientSecret] = useState(''); // Holds the Stripe clientSecret
    const [stripeOptions, setStripeOptions] = useState({});
    const [error, setError] = useState('');  // Stores error message from the backend

    useEffect(() => {
        const fetchEvent = async () => {
            try {
                const response = await EventService.getEventLanding(id);
                setEvent(response.data);
                setPaymentTypes(response.data.acceptedPaymentTypes);
                setPaymentType(response.data.acceptedPaymentTypes[0]);
                setFormValues((prev) => ({ ...prev, selectedPaymentType: response.data.acceptedPaymentTypes[0] }));
                setCategories(response.data.categories.map(category => ({
                    ...category,
                    quantity: 0
                })));
            } catch (error) {
                setError('Error loading event data.');
            } finally {
                setLoading(false);
            }
        };

        fetchEvent();
    }, [id]);

    const handleCategoryChange = (index, delta) => {
        const newCategories = [...categories];
        const currentCategory = newCategories[index];
        currentCategory.quantity = Math.max(currentCategory.quantity + delta, 0);
        setCategories(newCategories);
        updateTotalPrice(newCategories);
    };

    const updateTotalPrice = (categories) => {
        const total = categories.reduce((sum, category) => sum + category.price * category.quantity, 0);
        setTotalPrice(total);
    };

    const handlePaymentTypeChange = (e) => {
        const selectedType = e.target.value;
        setPaymentType(selectedType);
        setFormValues((prev) => ({ ...prev, selectedPaymentType: selectedType }));
    };

    const createCheckoutPayload = () => {
        // Create checkout payload with the required structure for categories
        return {
            name: formValues.name,
            mail: formValues.mail,
            paymentType: formValues.selectedPaymentType,
            eventId: event.id,
            categories: categories
                .filter(category => category.quantity > 0) // Only send categories with selected tickets
                .map(category => ({
                    numberOfTickets: category.quantity,
                    selectedCategory: {
                        id: category.id,
                        name: category.name,
                        price: category.price,
                        eventId: event.id,
                    },
                })),
        };
    };

    const checkoutOnSite = async () => {
        try {
            const checkoutDTO = createCheckoutPayload();
            await EventService.checkoutEvent(checkoutDTO);
            window.location.href = `${window.location.origin}/order/success`;
        } catch (error) {
            setError(error?.response?.data || 'Error creating order. Please try again.');
        }
    };

    const openModalAndCreateOrder = async () => {
        try {
            // Create order with full checkout payload and get clientSecret (secretKey) from backend
            const checkoutDTO = createCheckoutPayload();
            const response = await EventService.checkoutCard(checkoutDTO);
            setClientSecret(response.data.secretKey); // Store the clientSecret for Stripe PaymentElement
            setStripeOptions({
                clientSecret: response.data.secretKey,
            });

            setModalOpen(true); // Open modal after receiving the clientSecret
        } catch (error) {
            setError(error?.response?.data || 'Error creating order. Please try again.');
            setModalOpen(false); // Close modal if order creation fails
        }
    };

    return (

    <div className="min-h-screen bg-gray-100">
        <Header showNavbar={false} />

        <div className="container mx-auto py-8">
                {loading ? (
                    <div className="flex justify-center items-center">
                        <div className="spinner-border text-blue-600" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                ) : (
                    <div className="max-w-2xl mx-auto bg-white p-6 rounded-lg shadow-lg">
                        <div className="text-center">
                            <img src={event.imageUrl} alt={event.name} className="mx-auto w-3/4 rounded-lg" />
                            <h1 className="text-2xl font-bold mt-4">{event.name}</h1>
                            <p className="text-gray-600">{new Date(event.eventDate).toLocaleString()}</p>
                            <p className="mt-2">{event.description}</p>
                        </div>

                        {error && (
                            <div className="bg-red-100 border mt-2 border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
                                <span className="block sm:inline">{error}</span>
                            </div>
                        )}

                        <form onSubmit={(e) => e.preventDefault()} className="mt-8 space-y-6">
                            <div className="space-y-4">
                                <input
                                    type="text"
                                    placeholder="Your Name"
                                    value={formValues.name}
                                    onChange={(e) => setFormValues({ ...formValues, name: e.target.value })}
                                    className="w-full px-4 py-2 border rounded-md focus:ring focus:ring-indigo-300"
                                    required
                                />
                                <input
                                    type="email"
                                    placeholder="Your Mail"
                                    value={formValues.mail}
                                    onChange={(e) => setFormValues({ ...formValues, mail: e.target.value })}
                                    className="w-full px-4 py-2 border rounded-md focus:ring focus:ring-indigo-300"
                                    required
                                />
                            </div>

                            <div className="mt-6 space-y-4">
                                {categories.map((category, index) => (
                                    <div
                                        key={category.id}
                                        className="flex items-center justify-between p-4 border rounded-md shadow-sm bg-gray-50"
                                    >
                                        <div className="flex items-center space-x-2">
                                            <span className="inline-block w-4 h-4 bg-red-500 rounded-full"></span>
                                            <div className="text-lg font-semibold">{category.name}</div>
                                        </div>

                                        <div className="text-right">
                                            <p className="font-bold text-lg text-gray-900">€{(category.price / 100).toFixed(2)}</p>
                                            <div className="flex items-center mt-2">
                                                <button
                                                    type="button"
                                                    className="px-3 py-1 bg-gray-200 rounded-md"
                                                    onClick={() => handleCategoryChange(index, -1)}
                                                >
                                                    –
                                                </button>
                                                <span className="px-4">{category.quantity}</span>
                                                <button
                                                    type="button"
                                                    className="px-3 py-1 bg-green-200 rounded-md"
                                                    onClick={() => handleCategoryChange(index, 1)}
                                                >
                                                    +
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>

                            {paymentTypes.length > 1 && (
                                <div className="mt-4">
                                    <label className="block text-sm font-medium text-gray-700">Payment Method</label>
                                    <select
                                        value={paymentType}
                                        onChange={handlePaymentTypeChange}
                                        className="w-full px-4 py-2 border rounded-md focus:ring focus:ring-indigo-300"
                                        required
                                    >
                                        {paymentTypes.includes('ONSITE') && <option value="ONSITE">Pay On-Site</option>}
                                        {paymentTypes.includes('CARD') && <option value="CARD">Credit/Debit Card</option>}
                                    </select>
                                </div>
                            )}

                            <div className="mt-4 text-lg font-semibold text-right">
                                Total: €{(totalPrice / 100).toFixed(2)}
                            </div>

                            {paymentType === 'CARD' && (
                                <div className="mt-6">
                                    <button
                                        type="button"
                                        onClick={openModalAndCreateOrder}
                                        className="w-full py-2 rounded-md bg-blue-600 text-white font-semibold hover:bg-blue-700 transition duration-200"
                                    >
                                        Checkout with Card
                                    </button>
                                </div>
                            )}

                            {paymentType === 'ONSITE' && (
                                <button
                                    type="button"
                                    onClick={checkoutOnSite}
                                    className="w-full py-2 rounded-md bg-blue-600 text-white font-semibold hover:bg-blue-700 transition duration-200"
                                >
                                    Checkout On-Site
                                </button>
                            )}
                        </form>

                        {stripeOptions && modalOpen && (
                            <Elements stripe={stripePromise} options={stripeOptions}>
                                <PaymentModal onClose={() => setModalOpen(false)} />
                            </Elements>
                        )}

                    </div>
                )}
            </div>

            <Footer />

        </div>
    );
}

export default EventSell;
