import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import EventService from '../../services/EventService';
import Header from '../../core/Header';
import Globals from "../../core/globals";

function EventDetailed() {
    const { id } = useParams();
    const [event, setEvent] = useState(null);
    const [loading, setLoading] = useState(true);
    const [downloadLoading, setDownloadLoading] = useState(false);
    const [mailLoading, setMailLoading] = useState(false);
    const [error, setError] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        const fetchEvent = async () => {
            try {
                const response = await EventService.getEventById(id);
                setEvent(response.data);
            } catch (error) {
                setError("Error loading event: " + error.message);
                console.error("Error loading event:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchEvent();
    }, [id]);

    const jumpToLandingPage = () => {
        const formattedArtistName = event.artistName.replaceAll(" ", "-").toLowerCase();
        window.open(`${Globals.frontendUri}events/${formattedArtistName}/${event.id}`, '_blank');
    };

    const getListWithParticipants = async () => {
        setDownloadLoading(true);
        try {
            const response = await EventService.getParticipantsList(event.id);
            const blob = new Blob([response.data], { type: 'application/pdf' });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.target = '_blank';
            a.click();
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error("Error downloading participants list:", error);
        } finally {
            setDownloadLoading(false);
        }
    };

    const getListWithParticipantsPerMail = async () => {
        setMailLoading(true);
        try {
            await EventService.getListWithParticipantsPerMail(event.id);
            alert("Mail sent successfully!");
        } catch (error) {
            console.error("Error sending participants list via mail:", error);
        } finally {
            setMailLoading(false);
        }
    };

    const jumpToCheckin = () => {
        navigate(`/events/checkin/${event.id}`);
    };

    const editEvent = () => {
        navigate(`/events/edit/${event.id}`);
    };

    return (
        <div className="bg-gray-50 min-h-screen">
            <Header isLoggedIn={true} logoutUser={() => console.log('Logged out')} />
            <div className="container mx-auto py-8">
                {loading ? (
                    <div className="text-center">
                        <h3 className="text-lg font-semibold text-gray-700">
                            <span className="spinner-border spinner-border-sm mr-2"></span>
                            Loading Event...
                        </h3>
                    </div>
                ) : (
                    <div className="flex flex-col md:flex-row bg-white rounded-lg shadow-lg overflow-hidden">
                        {error && (
                            <div className="text-red-500 text-center p-4">
                                {error}
                            </div>
                        )}

                        {/* Event Image */}
                        <div className="md:w-1/2 p-4">
                            {event.imageUrl ? (
                                <img
                                    src={event.imageUrl}
                                    alt={event.name}
                                    className="w-full h-auto rounded-lg shadow-md object-cover"
                                />
                            ) : (
                                <div className="w-full h-64 bg-gray-200 flex items-center justify-center">
                                    <span>No Image</span>
                                </div>
                            )}
                        </div>

                        {/* Event Info */}
                        <div className="md:w-1/2 p-6">
                            <h2 className="text-3xl font-bold text-gray-800 mb-4">
                                {event.name}
                            </h2>
                            <h4 className="text-lg font-medium text-gray-700 mb-2">
                                Venue: {event.venue}
                            </h4>
                            <h4 className="text-lg font-medium text-gray-700 mb-2">
                                Date: {new Date(event.eventDate).toLocaleDateString('de-DE', { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit' })}
                            </h4>
                            {event.supportInformation && (
                                <h4 className="text-lg font-medium text-gray-700 mb-2">
                                    Support: {event.supportInformation}
                                </h4>
                            )}
                            <h4 className="text-lg font-medium text-gray-700 mb-2">
                                Tickets Sold: {event.ticketsSold}
                            </h4>
                            <h4 className="text-lg font-medium text-gray-700 mb-2">
                                Available Space: {event.capacity - event.ticketsSold}
                            </h4>
                            <h4 className="text-lg font-medium text-gray-700 mb-2">
                                Accepted Payment Types:
                            </h4>
                            {event.acceptedPaymentTypes.map((type, index) => (
                                <p key={index} className="text-gray-500">{type}</p>
                            ))}
                            <h4 className="text-lg font-medium text-gray-700 mb-2">
                                Current Revenue: €{(event.currentRevenue / 100).toFixed(2)}
                            </h4>
                            {event.expectedOnsiteRevenue > 0 && (
                                <h4 className="text-lg font-medium text-gray-700 mb-2">
                                    Expected Onsite Revenue: €{(event.expectedOnsiteRevenue / 100).toFixed(2)}
                                </h4>
                            )}
                            <h4 className="text-lg font-medium text-gray-700 mb-2">Billing Address:</h4>
                            <p className="text-gray-500">{event.invoiceName}</p>
                            <p className="text-gray-500">{event.invoiceStreet}</p>
                            <p className="text-gray-500">{event.invoiceZipCode} {event.invoiceTown}</p>
                            <p className="text-gray-500">{event.invoiceCountry}</p>
                            <p className="text-gray-500">VAT: {event.vatPercentage}%</p>

                            {/* Buttons */}
                            <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3 mt-6">
                                <button
                                    className="bg-blue-500 text-white rounded-md px-4 py-2 hover:bg-blue-600 transition-colors duration-200"
                                    onClick={jumpToLandingPage}
                                >
                                    <i className="bi bi-file-text"></i> Link
                                </button>
                                <button
                                    className={`bg-blue-500 text-white rounded-md px-4 py-2 ${downloadLoading ? 'opacity-50 cursor-not-allowed' : 'hover:bg-blue-600 transition-colors duration-200'}`}
                                    onClick={getListWithParticipants}
                                    disabled={downloadLoading}
                                >
                                    {downloadLoading && (
                                        <span className="spinner-border spinner-border-sm mr-2"></span>
                                    )}
                                    <i className="bi bi-download"></i> Tickets
                                </button>
                                <button
                                    className={`bg-blue-500 text-white rounded-md px-4 py-2 ${mailLoading ? 'opacity-50 cursor-not-allowed' : 'hover:bg-blue-600 transition-colors duration-200'}`}
                                    onClick={getListWithParticipantsPerMail}
                                    disabled={mailLoading}
                                >
                                    {mailLoading && (
                                        <span className="spinner-border spinner-border-sm mr-2"></span>
                                    )}
                                    <i className="bi bi-envelope"></i> Tickets (mail)
                                </button>
                                <button
                                    className="bg-blue-500 text-white rounded-md px-4 py-2 hover:bg-blue-600 transition-colors duration-200"
                                    onClick={jumpToCheckin}
                                >
                                    Jump To CheckIn
                                </button>
                                <button
                                    className="bg-blue-500 text-white rounded-md px-4 py-2 hover:bg-blue-600 transition-colors duration-200"
                                    onClick={editEvent}
                                >
                                    Edit Event
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default EventDetailed;
