import React from 'react';

function Imprint() {
    return (
        <div className="bg-gray-50 min-h-screen">
            <div className="container mx-auto py-12 px-4 sm:px-6 lg:px-8">
                <div className="bg-white shadow-lg rounded-lg p-8 max-w-2xl mx-auto">
                    <h1 className="text-3xl font-bold text-gray-800 mb-6 text-center">Imprint</h1>
                    <p className="text-lg text-gray-600 mb-4">
                        This imprint is required under §5 of the E-Commerce Act (ECG) and provides the contact details of the responsible person or company for this website.
                    </p>
                    <div className="border-t border-gray-200 my-4"></div>
                    <h2 className="text-xl font-semibold text-gray-700 mb-2">Contact Information:</h2>
                    <p className="text-gray-600 mb-1">Maximilian Höller</p>
                    <p className="text-gray-600 mb-1">Am Tobersbach 8</p>
                    <p className="text-gray-600 mb-1">4221 Steyregg</p>
                    <p className="text-gray-600 mb-1">AUSTRIA</p>

                    <div className="mt-6">
                        <h3 className="text-lg font-semibold text-gray-700 mb-2">Email:</h3>
                        <a href="mailto:max@labelboost.net" className="text-blue-500 hover:text-blue-700 transition-colors">
                            max@labelboost.net
                        </a>
                    </div>

                    <div className="mt-8">
                        <p className="text-sm text-gray-500">
                            This site is managed by Maximilian Höller, responsible for all content related to LabelBoost.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Imprint;
