import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import EventService from '../../services/EventService';
import Header from '../../core/Header';
import ArtistSelect from '../../core/ArtistSelect';
import Select from 'react-select';

function EventCreate() {
    const { id } = useParams(); // for edit mode, if id exists
    const navigate = useNavigate();
    const [form, setForm] = useState({
        name: '',
        venue: '',
        artistId: null, // This will hold the selected artist (object with label and value)
        eventDate: '',
        capacity: 0,
        vatPercentage: 0,
        description: '',
        acceptedPaymentTypes: [],
        categories: [
            { name: '', price: 0, id: '', eventId: '' },
        ],
    });
    const [isSaving, setIsSaving] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const paymentOptions = [
        { label: 'On Site', value: 'ONSITE' },
        { label: 'Credit/Debit Card', value: 'CARD' }
    ];

    useEffect(() => {
        if (id) {
            setEditMode(true);
            async function fetchEvent() {
                try {
                    const response = await EventService.getEventById(id);
                    setForm({
                        ...response.data,
                        artistId: response.data.artistId,
                        eventDate: new Date(response.data.eventDate).toISOString().substring(0, 16), // Format for datetime-local
                        categories: response.data.categories.map(cat => ({ name: cat.name, price: cat.price / 100, id: cat.id, eventId: cat.eventId })),
                        acceptedPaymentTypes: response.data.acceptedPaymentTypes.map(type => ({
                            label: paymentOptions.find(option => option.value === type)?.label || type,
                            value: type
                        }))
                    });
                } catch (error) {
                    setErrorMessage('Error loading event data.');
                }
            }
            fetchEvent();
        }
    }, [id]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setForm((prevForm) => ({
            ...prevForm,
            [name]: value,
        }));
    };

    const handleCategoryChange = (index, field, value) => {
        const newCategories = form.categories.map((category, i) =>
            i === index ? { ...category, [field]: value } : category
        );
        setForm({ ...form, categories: newCategories });
    };

    const addTicketCategory = () => {
        setForm((prevForm) => ({
            ...prevForm,
            categories: [...prevForm.categories, { name: '', price: 0 }],
        }));
    };

    const removeTicketCategory = async (index) => {
        const categoryToDelete = form.categories[index];

        // Check if the category has an ID (i.e., it exists in the backend)
        if (categoryToDelete.id) {
            try {
                // Call the backend to delete the category
                await EventService.deleteCategory(categoryToDelete.id);

                // If successful, remove the category from the frontend form
                const newCategories = form.categories.filter((_, i) => i !== index);
                setForm({ ...form, categories: newCategories });
            } catch (error) {
                // If there is an error, display the error message
                setErrorMessage(error.response?.data || 'An error occurred while deleting the category. Please try again.');
            }
        } else {
            // If no ID (category is new or not saved), just remove it from the form
            const newCategories = form.categories.filter((_, i) => i !== index);
            setForm({ ...form, categories: newCategories });
        }
    };


    const handleArtistChange = (selectedArtist) => {
        setForm({ ...form, artistId: selectedArtist });
    };

    const handlePaymentTypeChange = (selectedOptions) => {
        setForm({ ...form, acceptedPaymentTypes: selectedOptions });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSaving(true);
        setErrorMessage('');

        // Adjust category prices to be in cents before sending
        const adjustedCategories = form.categories.map(category => ({
            ...category,
            price: Math.round(category.price * 100), // Convert to cents
        }));

        const payload = {
            ...form,
            categories: adjustedCategories,
            artistId: form.artistId?.value || form.artistId,
            acceptedPaymentTypes: form.acceptedPaymentTypes.map(option => option.value) // Send only the values of selected payment types
        };

        try {
            if (editMode) {
                // Update event
                await EventService.updateEvent(payload);
                navigate('/events');
            } else {
                // Create event
                await EventService.createEvent(payload);
                navigate('/events');
            }
        } catch (error) {
            setErrorMessage(error.response?.data || 'An error occurred. Please try again.');
        } finally {
            setIsSaving(false);
        }
    };

    return (
        <div className="min-h-screen bg-gray-100">
            <Header isLoggedIn={true} logoutUser={() => console.log('Logged out')} />
            <div className="container mx-auto p-6">
                <h2 className="text-3xl font-bold text-gray-800 mb-6">{editMode ? 'Edit Event' : 'Create Event'}</h2>
                <form className="space-y-8 bg-white p-6 rounded-lg shadow-md" onSubmit={handleSubmit}>

                    {/* Display Error Message */}
                    {errorMessage && (
                        <div className="mb-4 text-red-600">
                            {errorMessage}
                        </div>
                    )}

                    {/* Event Name */}
                    <div>
                        <label className="block text-sm font-medium text-gray-700 mb-2">Event Name</label>
                        <input
                            type="text"
                            name="name"
                            value={form.name}
                            onChange={handleChange}
                            className="block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                            required
                        />
                    </div>

                    {/* Venue and Date */}
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-2">Venue</label>
                            <input
                                type="text"
                                name="venue"
                                value={form.venue}
                                onChange={handleChange}
                                className="block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                                required
                            />
                        </div>
                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-2">Event Date</label>
                            <input
                                type="datetime-local"
                                name="eventDate"
                                value={form.eventDate}
                                onChange={handleChange}
                                className="block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                                required
                            />
                        </div>
                    </div>

                    {/* Artist Select */}
                    <div>
                        <label className="block text-sm font-medium text-gray-700 mb-2">Select Artist</label>
                        <ArtistSelect
                            placeholder="Select artist"
                            multiple={false}
                            onArtistChange={handleArtistChange}
                            value={form.artistId}
                        />
                    </div>

                    {/* Categories */}
                    <div>
                        <label className="block text-sm font-medium text-gray-700 mb-2">Ticket Categories</label>
                        {form.categories.map((category, index) => (
                            <div key={index} className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-4">
                                <div>
                                    <input
                                        type="text"
                                        value={category.name}
                                        onChange={(e) => handleCategoryChange(index, 'name', e.target.value)}
                                        className="block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                                        placeholder="Category Name"
                                    />
                                </div>
                                <div>
                                    <input
                                        type="number"
                                        value={category.price}
                                        onChange={(e) => handleCategoryChange(index, 'price', e.target.value)}
                                        className="block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                                        placeholder="Ticket Price (€)"
                                        min="0"
                                    />
                                </div>
                                <div className="flex items-center">
                                    <button
                                        type="button"
                                        onClick={() => removeTicketCategory(index)}
                                        className="text-red-600 hover:text-red-800"
                                    >
                                        Remove
                                    </button>
                                </div>
                            </div>
                        ))}
                        <button
                            type="button"
                            onClick={addTicketCategory}
                            className="flex items-center text-blue-500 hover:text-blue-700"
                        >
                            <svg
                                className="w-5 h-5 mr-1"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                      d="M12 4v16m8-8H4"></path>
                            </svg>
                            Add Category
                        </button>
                    </div>

                    {/* Payment Type */}
                    <div>
                        <label className="block text-sm font-medium text-gray-700 mb-2">Accepted Payment Types</label>
                        <Select
                            isMulti
                            options={paymentOptions}
                            value={form.acceptedPaymentTypes}
                            onChange={handlePaymentTypeChange}
                            className="basic-multi-select"
                            classNamePrefix="select"
                        />
                    </div>

                    {/* Venue Capacity */}
                    <div>
                        <label className="block text-sm font-medium text-gray-700 mb-2">Venue Capacity</label>
                        <input
                            type="number"
                            name="capacity"
                            value={form.capacity}
                            onChange={handleChange}
                            className="block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                            placeholder="Venue Capacity"
                            min="0"
                            required
                        />
                    </div>

                    {/* VAT Percentage */}
                    <div>
                        <label className="block text-sm font-medium text-gray-700 mb-2">VAT Percentage</label>
                        <input
                            type="number"
                            name="vatPercentage"
                            value={form.vatPercentage}
                            onChange={handleChange}
                            className="block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                            placeholder="VAT Percentage"
                            min="0"
                            required
                        />
                    </div>

                    {/* Description */}
                    <div>
                        <label className="block text-sm font-medium text-gray-700 mb-2">Event Description</label>
                        <textarea
                            name="description"
                            value={form.description}
                            onChange={handleChange}
                            className="block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                            rows="5"
                            placeholder="Enter event description"
                        />
                    </div>

                    {/* Submit Button */}
                    <div className="flex justify-end">
                        <button
                            type="submit"
                            className={`bg-blue-500 text-white px-6 py-2 rounded-md shadow-md ${isSaving ? 'opacity-50 cursor-not-allowed' : 'hover:bg-blue-600'} focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50`}
                            disabled={isSaving}
                        >
                            {isSaving ? (editMode ? 'Updating...' : 'Saving...') : (editMode ? 'Update Event' : 'Save Event')}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default EventCreate;
