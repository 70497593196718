import React, { useState, useEffect } from 'react';
import BillingService from '../../services/BillingService';
import Header from '../../core/Header';
import AuthService from '../../services/AuthService';
import ArtistSelect from '../../core/ArtistSelect';

function BillingComponent() {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [artistSelected, setArtistSelected] = useState(null);
    const [invoices, setInvoices] = useState([]);
    const [payoutRequests, setPayoutRequests] = useState([]);
    const [saldo, setSaldo] = useState({ saldo: 0, requestedSaldo: 0 });
    const [error, setError] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [creatingPayoutRequest, setCreatingPayoutRequest] = useState(false);

    useEffect(() => {
        loadUserData();
    }, []);

    const loadUserData = async () => {
        try {
            const userData = await AuthService.getUserInformation();
            setUser(userData.data);
            setLoading(false);
        } catch (error) {
            console.error('Error loading user data', error);
            setLoading(false);
            setError('Error loading user data.');
        }
    };

    const handleArtistChange = async (selectedArtist) => {
        try {
            const artistId = selectedArtist.value;
            const invoicesData = await BillingService.getInvoicesForArtist(artistId);
            const payoutData = await BillingService.getPayoutRequestsForArtist(artistId);
            const saldoData = await BillingService.getPayoutSaldoForArtist(artistId);

            setInvoices(invoicesData.data);
            setPayoutRequests(payoutData.data);
            setSaldo(saldoData.data.saldo);
            setArtistSelected(artistId);
            setError('');
        } catch (error) {
            console.error('Error loading billing details', error);
            setError('Failed to load billing details.');
        }
    };

    const handleCreatePayoutRequest = async () => {
        if (artistSelected) {
            setCreatingPayoutRequest(true);
            try {
                await BillingService.createPayoutRequest(artistSelected);
                setSuccessMessage('Payout request created successfully!');
                setError('');
                handleArtistChange({ value: artistSelected }); // Reload data after creation
            } catch (error) {
                console.error('Error creating payout request', error);
                setError(error.response?.data || 'Invalid email or password.');
                setSuccessMessage('');
            } finally {
                setCreatingPayoutRequest(false);
            }
        }
    };

    const downloadInvoice = async (invoiceId) => {
        try {
            const response = await BillingService.downloadInvoice(invoiceId);
            const blob = new Blob([response.data], { type: 'application/pdf' });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.target = '_blank';
            a.click();
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Error downloading invoice', error);
        }
    };

    const downloadPayoutRequest = async (requestId) => {
        try {
            const response = await BillingService.downloadPayoutRequestInvoice(requestId);
            const blob = new Blob([response.data], { type: 'application/pdf' });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.target = '_blank';
            a.click();
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Error downloading payout request', error);
        }
    };

    return (
        <div className="bg-gray-50 min-h-screen">
            <Header isLoggedIn={AuthService.isLoggedIn()} logoutUser={AuthService.logoutUser} />
            <div className="container mx-auto py-8">
                {loading ? (
                    <div className="text-center">
                        <h3 className="text-lg font-semibold text-gray-700">
                            <span className="spinner-border spinner-border-sm mr-2"></span>
                            Loading Billing Information...
                        </h3>
                    </div>
                ) : (
                    <div className="bg-white rounded-lg shadow-lg p-6">
                        <h3 className="text-2xl font-bold text-gray-800 mb-6">Billing</h3>

                        {/* Success and Error Messages */}
                        {successMessage && <p className="text-green-600 mb-4">{successMessage}</p>}
                        {error && <p className="text-red-500 mb-4">{error}</p>}

                        {/* Subscription Info */}
                        {user && (
                            <div>
                                <p className="text-lg">
                                    Hey {user.firstName}! You are currently subscribed to the{' '}
                                    <strong>{user.plan}</strong> plan.{' '}
                                    {user.plan !== 'FREE' ? (
                                        <span>
                                            Manage your subscription{' '}
                                            <a href={user.stripeDashboardUrl} className="text-blue-500">
                                                here
                                            </a>.
                                        </span>
                                    ) : (
                                        <span>
                                            Upgrade to the{' '}
                                            <a href={user.artistPlanUrl} className="text-blue-500">
                                                artist plan
                                            </a>{' '}
                                            or the{' '}
                                            <a href={user.labelPlanUrl} className="text-blue-500">
                                                label plan
                                            </a>.
                                        </span>
                                    )}
                                </p>
                            </div>
                        )}

                        {/* Artist Select */}
                        <div className="mt-8">
                            <h4 className="text-xl font-semibold mb-4">Select Artist</h4>
                            <ArtistSelect
                                placeholder="Select artist"
                                multiple={false}
                                onArtistChange={handleArtistChange}
                            />
                        </div>

                        {/* Payout Requests */}
                        {artistSelected && (
                            <div className="mt-8">
                                <h4 className="text-xl font-semibold mb-4">Payout Requests</h4>
                                <p>
                                    The artist has a saldo of €{(saldo.saldo / 100).toFixed(2)}. You can request payout
                                    by clicking new payout request. €{(saldo.requestedSaldo / 100).toFixed(2)} has
                                    already been requested but not yet paid out.
                                </p>
                                <button
                                    className={`bg-green-500 text-white rounded-md px-4 py-2 hover:bg-green-600 transition duration-200 mt-4 ${creatingPayoutRequest ? 'opacity-50 cursor-not-allowed' : ''}`}
                                    onClick={handleCreatePayoutRequest}
                                    disabled={creatingPayoutRequest}
                                >
                                    {creatingPayoutRequest ? 'Creating Payout Request...' : '+ Create Payout Request'}
                                </button>

                                {payoutRequests.length > 0 ? (
                                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 mt-4">
                                        {payoutRequests.map((request) => (
                                            <div
                                                key={request.id}
                                                className="bg-gray-100 p-4 rounded-lg shadow cursor-pointer hover:bg-gray-200 transition duration-200"
                                                onClick={() => downloadPayoutRequest(request.id)}
                                            >
                                                <h5 className="font-bold">ID: {request.id}</h5>
                                                <p >Date: {new Date(request.dateCreated).toLocaleDateString('en-US', {
                                                    year: 'numeric',
                                                    month: 'long',
                                                    day: 'numeric',
                                                })}</p>
                                                <p>Payout Amount: €{(request.payoutAmount / 100).toFixed(2)}</p>
                                                <p>Status: {request.status}</p>
                                            </div>
                                        ))}
                                    </div>
                                ) : (
                                    <p>No payout requests available for the selected artist.</p>
                                )}
                            </div>
                        )}

                        {/* Invoices */}
                        {artistSelected && (
                            <div className="mt-8">
                                <h4 className="text-xl font-semibold mb-4">Invoices</h4>
                                {invoices.length > 0 ? (
                                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
                                        {invoices.map((invoice) => (
                                            <div
                                                key={invoice.id}
                                                className="bg-gray-100 p-4 rounded-lg shadow cursor-pointer hover:bg-gray-200 transition duration-200"
                                                onClick={() => downloadInvoice(invoice.id)}
                                            >
                                                <h5 className="font-bold">Invoice #{invoice.invoiceNumber}</h5>
                                                <p>Customer: {invoice.customerMail}</p>
                                                <p>Total Price: €{(invoice.totalPrice / 100).toFixed(2)}</p>
                                                <p>
                                                    Date: {new Date(invoice.invoiceDate).toLocaleDateString('en-US', {
                                                    year: 'numeric',
                                                    month: 'long',
                                                    day: 'numeric',
                                                })}
                                                </p>
                                                <p>Status: {invoice.paymentStatus}/{invoice.payoutStatus}</p>
                                            </div>
                                        ))}
                                    </div>
                                ) : (
                                    <p>No invoices available for the selected artist.</p>
                                )}
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
}

export default BillingComponent;
