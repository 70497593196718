import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import AuthService from "../services/AuthService";

const PasswordResetSet = () => {
    const { token } = useParams();
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [hidePassword, setHidePassword] = useState(true);
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const togglePasswordVisibility = () => {
        setHidePassword(!hidePassword);
    };

    const handleChangePassword = async (e) => {
        e.preventDefault();

        if (newPassword !== confirmPassword) {
            setError('Passwords do not match!');
            return;
        }

        try {
            await AuthService.resetPassword({
                newPassword,
                token,
            });
            navigate('/login');
        } catch (error) {
            setError(error?.response?.data || 'Failed to reset password.');
        }
    };

    return (
        <div className="min-h-screen flex items-center justify-center bg-gray-100">
            <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-sm">
                <h2 className="text-2xl font-bold mb-6 text-center text-gray-800">Set a New Password</h2>
                {error && <div className="mb-4 text-red-500 text-sm">{error}</div>}
                <form onSubmit={handleChangePassword}>
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="newPassword">
                            New Password
                        </label>
                        <div className="relative">
                            <input
                                type={hidePassword ? 'password' : 'text'}
                                id="newPassword"
                                placeholder="New Password"
                                value={newPassword}
                                onChange={(e) => setNewPassword(e.target.value)}
                                className="w-full px-4 py-2 border rounded-md focus:ring focus:ring-indigo-300"
                                required
                                minLength="8"
                            />
                            <button
                                type="button"
                                className="absolute inset-y-0 right-0 px-4 flex items-center text-gray-600"
                                onClick={togglePasswordVisibility}
                            >
                                {hidePassword ? 'Show' : 'Hide'}
                            </button>
                        </div>
                    </div>
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="confirmPassword">
                            Confirm New Password
                        </label>
                        <div className="relative">
                            <input
                                type={hidePassword ? 'password' : 'text'}
                                id="confirmPassword"
                                placeholder="Confirm New Password"
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                className="w-full px-4 py-2 border rounded-md focus:ring focus:ring-indigo-300"
                                required
                                minLength="8"
                            />
                        </div>
                    </div>
                    <button
                        type="submit"
                        className="w-full py-2 rounded-md bg-blue-600 text-white font-semibold hover:bg-blue-700 transition duration-200"
                    >
                        Change Password
                    </button>
                </form>
            </div>
        </div>
    );
};

export default PasswordResetSet;
