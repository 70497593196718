import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { DndContext, closestCenter } from '@dnd-kit/core';
import { SortableContext, arrayMove, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import Header from '../../core/Header';
import ArtistService from '../../services/ArtistService';
import { toast } from 'react-toastify';

const keyLabelMap = {
    facebookLink: 'Facebook Link',
    twitterLink: 'Twitter Link',
    instagramLink: 'Instagram Link',
    youtubeLink: 'YouTube Link',
    spotifyLink: 'Spotify Link',
    appleMusicLink: 'Apple Music Link',
    deezerLink: 'Deezer Link',
    soundCloudLink: 'SoundCloud Link',
    tikTokLink: 'TikTok Link',
    upcomingCampaigns: 'Upcoming Campaigns',
    previousCampaigns: 'Previous Campaigns',
    upcomingEvents: 'Upcoming Events'
};

function ArtistLinkCreate() {
    const { artistId } = useParams();
    const navigate = useNavigate();
    const [editMode, setEditMode] = useState(false);

    const [artistLinkForm, setArtistLinkForm] = useState({
        identifier: '',
        facebookLink: '',
        twitterLink: '',
        instagramLink: '',
        youtubeLink: '',
        spotifyLink: '',
        appleMusicLink: '',
        deezerLink: '',
        soundCloudLink: '',
        tikTokLink: '',
        artistId: artistId || '',
        showPreviousCampaigns: false,
        showUpcomingCampaigns: false,
        showUpcomingEvents: false,
        order: []
    });

    // Fetch initial data from the backend
    useEffect(() => {
        if (artistId) {
            ArtistService.getArtistLinkByArtistId(artistId)
                .then(response => {
                    const artistData = response.data;
                    setArtistLinkForm({
                        identifier: artistData.identifier || '',
                        facebookLink: artistData.facebookLink || '',
                        twitterLink: artistData.twitterLink || '',
                        instagramLink: artistData.instagramLink || '',
                        youtubeLink: artistData.youtubeLink || '',
                        spotifyLink: artistData.spotifyLink || '',
                        appleMusicLink: artistData.appleMusicLink || '',
                        deezerLink: artistData.deezerLink || '',
                        soundCloudLink: artistData.soundCloudLink || '',
                        tikTokLink: artistData.tikTokLink || '',
                        artistId: artistId,
                        showPreviousCampaigns: artistData.showPreviousCampaigns || false,
                        showUpcomingCampaigns: artistData.showUpcomingCampaigns || false,
                        showUpcomingEvents: artistData.showUpcomingEvents || false,
                        order: artistData.order || []
                    });
                    setEditMode(true);
                })
                .catch(error => {
                    console.error('Error fetching artist data:', error);
                });
        }
    }, [artistId]);

    // Update the order array when links or checkboxes change
    useEffect(() => {
        setArtistLinkForm(prevState => {
            const newOrder = [...prevState.order];  // Clone current order

            Object.keys(keyLabelMap).forEach(key => {
                const isLinkFilled = prevState[key] !== '' && prevState[key] !== undefined;
                const isChecked = prevState[`show${key.charAt(0).toUpperCase() + key.slice(1)}`];

                // Add key if it's a filled link or checked campaign/event toggle and not already in order
                if ((isLinkFilled || isChecked) && !newOrder.includes(key)) {
                    newOrder.push(key);
                }

                // Remove key if empty link or unchecked toggle and exists in order
                if ((!isLinkFilled && !isChecked) && newOrder.includes(key)) {
                    newOrder.splice(newOrder.indexOf(key), 1);
                }
            });

            return {
                ...prevState,
                order: newOrder
            };
        });
    }, [
        artistLinkForm.facebookLink,
        artistLinkForm.twitterLink,
        artistLinkForm.instagramLink,
        artistLinkForm.youtubeLink,
        artistLinkForm.spotifyLink,
        artistLinkForm.appleMusicLink,
        artistLinkForm.deezerLink,
        artistLinkForm.soundCloudLink,
        artistLinkForm.tikTokLink,
        artistLinkForm.showUpcomingCampaigns,
        artistLinkForm.showPreviousCampaigns,
        artistLinkForm.showUpcomingEvents
    ]);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setArtistLinkForm(prevState => ({
            ...prevState,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await ArtistService.editArtistLink(artistLinkForm);
            toast.success("Link updated successfully");
            navigate(`/artists/${artistId}`);
        } catch (error) {
            toast.error(error.response?.data || 'Failed to update link');
        }
    };

    const handleDragEnd = (event) => {
        const { active, over } = event;

        if (active.id !== over.id) {
            setArtistLinkForm(prevState => ({
                ...prevState,
                order: arrayMove(prevState.order, prevState.order.indexOf(active.id), prevState.order.indexOf(over.id))
            }));
        }
    };

    function SortableItem({ id }) {
        const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });
        const style = {
            transform: CSS.Transform.toString(transform),
            transition,
        };

        return (
            <li ref={setNodeRef} style={style} {...attributes} {...listeners} className="p-2 bg-gray-100 rounded-md shadow-md cursor-move">
                {keyLabelMap[id]}
            </li>
        );
    }

    return (
        <div className="min-h-screen bg-gray-100">
            <Header isLoggedIn={true} logoutUser={() => console.log('Logged out')} />
            <div className="container mx-auto p-6">
                <h2 className="text-3xl font-bold text-gray-800 mb-6">{editMode ? 'Edit Artist Link' : 'Create Artist Link'}</h2>
                <form className="space-y-6 bg-white p-6 rounded-lg shadow-md" onSubmit={handleSubmit}>

                    {/* Identifier */}
                    <div>
                        <label className="block text-sm font-medium text-gray-700 mb-2">Identifier</label>
                        <input
                            type="text"
                            name="identifier"
                            value={artistLinkForm.identifier || ''}
                            onChange={handleChange}
                            className="block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                            placeholder="Enter Identifier"
                        />
                    </div>

                    {/* Social Media & Streaming Links */}
                    {Object.keys(keyLabelMap).filter(key => !['upcomingCampaigns', 'previousCampaigns', 'upcomingEvents'].includes(key)).map(key => (
                        <div key={key}>
                            <label className="block text-sm font-medium text-gray-700 mb-2">{keyLabelMap[key]}</label>
                            <input
                                type="text"
                                name={key}
                                value={artistLinkForm[key] || ''}
                                onChange={handleChange}
                                className="block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                                placeholder={`Enter ${keyLabelMap[key]}`}
                            />
                        </div>
                    ))}

                    {/* Show Campaigns & Events Checkboxes */}
                    <div className="mt-4">
                        <label className="flex items-center text-sm font-medium text-gray-700">
                            <input
                                type="checkbox"
                                name="showPreviousCampaigns"
                                checked={artistLinkForm.showPreviousCampaigns}
                                onChange={handleChange}
                                className="mr-2"
                            />Show Previous Campaigns</label>
                    </div>
                    <div className="mt-4">
                        <label className="flex items-center text-sm font-medium text-gray-700">
                            <input
                                type="checkbox"
                                name="showUpcomingCampaigns"
                                checked={artistLinkForm.showUpcomingCampaigns}
                                onChange={handleChange}
                                className="mr-2"
                            />Show Upcoming Campaigns</label>
                    </div>
                    <div className="mt-4">
                        <label className="flex items-center text-sm font-medium text-gray-700">
                            <input
                                type="checkbox"
                                name="showUpcomingEvents"
                                checked={artistLinkForm.showUpcomingEvents}
                                onChange={handleChange}
                                className="mr-2"
                            />Show Upcoming Events</label>
                    </div>

                    {/* Order Links */}
                    <div className="mt-6">
                        <h3 className="text-lg font-medium text-gray-700 mb-2">Reorder Links</h3>
                        <DndContext collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
                            <SortableContext items={artistLinkForm.order} strategy={verticalListSortingStrategy}>
                                <ul className="space-y-2">
                                    {artistLinkForm.order.map(item => (
                                        <SortableItem key={item} id={item} />
                                    ))}
                                </ul>
                            </SortableContext>
                        </DndContext>
                    </div>

                    {/* Submit Button */}
                    <div className="flex justify-end">
                        <button
                            type="submit"
                            className="bg-blue-500 text-white px-6 py-2 rounded-md shadow-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
                        >
                            {editMode ? 'Update Artist Link' : 'Create Artist Link'}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default ArtistLinkCreate;
