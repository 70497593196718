import axiosInstance from './axiosInstance';
import Globals from '../core/globals';
import axios from "axios";
const axiosInstanceNoToken = axios.create({
    baseURL: Globals.backendUri,
});
class CampaignService {
    constructor() {
        this.baseUri = `${Globals.backendUri}/campaigns/`;
    }

    getCampaignById(id) {
        return axiosInstance.get(`${this.baseUri}${id}`);
    }

    getTopItems(id) {
        return axiosInstance.get(`${this.baseUri}top/${id}`);
    }

    getLotteryWinner(id) {
        return axiosInstance.get(`${this.baseUri}lottery/${id}`, {
            params: {
                whitelabelMails: "max@onelastglance.com",
            },
        });
    }

    exportCsv(id) {
        return axiosInstance.get(`${this.baseUri}csv/${id}`, {
            responseType: 'blob',
        });
    }

    checkContains(id) {
        return axiosInstance.get(`${this.baseUri}pre-saves/${id}`);
    }

    getCampaignByUrlSuffix(suffix) {
        return axiosInstanceNoToken.get(`${this.baseUri}page${suffix}`);
    }

    registerUser(code, state) {
        return axiosInstanceNoToken.post(`${this.baseUri}tokens`, {
            code: code,
            campaignId: state,
        });
    }


}

export default new CampaignService();
