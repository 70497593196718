import React, {useState, useEffect} from 'react';
import Header from '../../core/Header';
import EventService from '../../services/EventService';
import {useNavigate} from "react-router-dom";
import {FaPlus, FaTrash} from 'react-icons/fa';

function EventOverview() {
    const [events, setEvents] = useState([]);
    const [loadingEvents, setLoadingEvents] = useState(true);
    const [upcomingEvents, setUpcomingEvents] = useState([]);
    const [pastEvents, setPastEvents] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchEvents = async () => {
            try {
                const response = await EventService.getAllEvents();
                const eventsData = response.data.map(event => ({
                    ...event,
                    eventDate: new Date(event.eventDate),
                }));

                // Sort events by date in ascending order
                const sortedEvents = eventsData.sort((a, b) => a.eventDate - b.eventDate);

                setUpcomingEvents(sortedEvents.filter(event => event.eventDate > new Date()));
                setPastEvents(sortedEvents.filter(event => event.eventDate <= new Date()));
                setEvents(sortedEvents);
            } catch (error) {
                console.error("Error fetching events:", error);
            } finally {
                setLoadingEvents(false);
            }
        };

        fetchEvents();
    }, []);


    const noEvents = upcomingEvents.length === 0 && pastEvents.length === 0;

    const navigateToEventDetail = (id) => {
        navigate(`/events/${id}`);
    };

    const createEvent = () => {
        navigate('/events/create');
    };


    return (
        <div className="min-h-screen bg-gray-100">
            <Header isLoggedIn={true} logoutUser={() => console.log("Logged out")}/>
            <div className="container mx-auto p-6">
                <div className="flex justify-between items-center mb-6">
                    <h1 className="text-3xl font-bold text-gray-800">Your Events</h1>
                    <button
                        className="bg-blue-500 text-white px-4 py-2 rounded-md shadow-md hover:bg-blue-600 flex items-center"
                        onClick={createEvent}
                    >
                        <FaPlus className="mr-2"/> Event
                    </button>
                </div>

                {loadingEvents ? (
                    <div className="text-center">
                        <span className="spinner-border spinner-border-sm mr-2"></span> Loading Events
                    </div>
                ) : (
                    <>
                        {noEvents ? (
                            <div className="text-center">
                                <h3>No events yet</h3>
                            </div>
                        ) : (
                            <div>
                                <div>
                                    <h3 className="text-2xl font-semibold mt-6 mb-4">Upcoming Events</h3>
                                    {upcomingEvents.length === 0 ? (
                                        <p>There are no upcoming events</p>
                                    ) : (
                                        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                                            {upcomingEvents.map(event => (
                                                <div
                                                    key={event.id}
                                                    className="block border rounded-lg p-4 mb-4 shadow-sm hover:shadow-lg transition-shadow duration-200 flex items-center justify-between cursor-pointer"
                                                    onClick={() => navigateToEventDetail(event.id)}
                                                >
                                                    <div className="flex items-center">
                                                        {event.imageUrl ? (
                                                            <img
                                                                src={event.imageUrl}
                                                                alt={event.name}
                                                                className="h-24 w-24 mr-4 object-cover rounded"
                                                            />
                                                        ) : (
                                                            <div
                                                                className="h-24 w-24 mr-4 bg-gray-200 flex items-center justify-center">
                                                                <span>No Image</span>
                                                            </div>
                                                        )}
                                                        <div>
                                                            <h4 className="text-xl font-bold text-gray-800">{event.name}</h4>
                                                            <p className="text-gray-500">
                                                                {event.venue} ({event.eventDate.toLocaleDateString('de-DE', {
                                                                day: '2-digit',
                                                                month: '2-digit',
                                                                year: 'numeric',
                                                                hour: '2-digit',
                                                                minute: '2-digit'
                                                            })})
                                                            </p>
                                                            <p className="text-gray-500">Tickets sold: {event.ticketsSold } / {event.capacity}</p>
                                                        </div>
                                                    </div>

                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </div>

                                <div>
                                    <h3 className="text-2xl font-semibold mt-6 mb-4">Previous Events</h3>
                                    {pastEvents.length === 0 ? (
                                        <p>There are no previous events</p>
                                    ) : (
                                        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                                            {pastEvents.map(event => (
                                                <div
                                                    key={event.id}
                                                    className="block border rounded-lg p-4 mb-4 shadow-sm hover:shadow-lg transition-shadow duration-200 flex items-center justify-between cursor-pointer"
                                                    onClick={() => navigateToEventDetail(event.id)}
                                                >
                                                    <div className="flex items-center">
                                                        {event.imageUrl ? (
                                                            <img
                                                                src={event.imageUrl}
                                                                alt={event.name}
                                                                className="h-24 w-24 mr-4 object-cover rounded"
                                                            />
                                                        ) : (
                                                            <div
                                                                className="h-24 w-24 mr-4 bg-gray-200 flex items-center justify-center">
                                                                <span>No Image</span>
                                                            </div>
                                                        )}
                                                        <div>
                                                            <h4 className="text-xl font-bold text-gray-800">{event.name}</h4>
                                                            <p className="text-gray-500">
                                                                {event.venue} ({event.eventDate.toLocaleDateString('de-DE', {
                                                                day: '2-digit',
                                                                month: '2-digit',
                                                                year: 'numeric',
                                                                hour: '2-digit',
                                                                minute: '2-digit'
                                                            })})
                                                            </p>
                                                            <p className="text-gray-500">Tickets
                                                                sold: {event.ticketsSold} / {event.capacity}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                    </>
                )}
            </div>
        </div>
    );
}

export default EventOverview;
