import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import ArtistService from '../../services/ArtistService';
import Header from '../../core/Header';

function ArtistDetail() {
    const { id } = useParams();
    const [artist, setArtist] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        const fetchArtist = async () => {
            try {
                const response = await ArtistService.getArtistById(id);
                setArtist(response.data);
            } catch (error) {
                setError("Error loading artist: " + error.message);
                console.error("Error loading artist:", error);
            } finally {
                setLoading(false);
            }
        };
        fetchArtist();
    }, [id]);

    const handleEdit = () => {
        navigate(`/artists/edit/${artist.id}`);
    };

    const handleEditLink = () => {
        navigate(`/artists/link/edit/${artist.id}`);
    };

    const navigateToLink = () => {
        window.location.href = artist.artistLinkUrl;
    };

    return (
        <div className="bg-gray-50 min-h-screen">
            <Header isLoggedIn={true} logoutUser={() => console.log('Logged out')} />
            <div className="container mx-auto py-8">
                {loading ? (
                    <div className="text-center">
                        <h3 className="text-lg font-semibold text-gray-700">
                            <span className="spinner-border spinner-border-sm mr-2"></span>
                            Loading Artist...
                        </h3>
                    </div>
                ) : (
                    <div className="flex flex-col md:flex-row bg-white rounded-lg shadow-lg overflow-hidden">
                        {error && (
                            <div className="text-red-500 text-center p-4">
                                {error}
                            </div>
                        )}
                        {/* Artist Image */}
                        <div className="md:w-1/2 p-4">
                            <img
                                src={artist.imageUrl}
                                alt={artist.name}
                                className="w-full h-auto rounded-lg shadow-md object-cover"
                            />
                        </div>

                        {/* Artist Info */}
                        <div className="md:w-1/2 p-6">
                            <h2 className="text-3xl font-bold text-gray-800 mb-4">
                                {artist.name}
                            </h2>
                            <div className="mb-4">
                                {artist.followers && (<h4 className="text-lg font-medium text-gray-700">
                                    Followers: {artist?.followers?.toLocaleString()}
                                </h4>)}
                                {artist.popularity && (<h4 className="text-lg font-medium text-gray-700">
                                    Popularity: {artist.popularity}
                                </h4>)}
                                {artist.description && (
                                    <p className="text-md text-gray-600 mt-2">
                                        {artist.description}
                                    </p>
                                )}
                            </div>

                            <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
                                <button
                                    className="bg-blue-500 text-white rounded-md px-4 py-2 hover:bg-blue-600 transition-colors duration-200"
                                    onClick={handleEdit}
                                >
                                    <i className="bi bi-pencil"></i> Edit Artist
                                </button>
                                <button
                                    className="bg-blue-500 text-white rounded-md px-4 py-2 hover:bg-blue-600 transition-colors duration-200"
                                    onClick={handleEditLink}
                                >
                                    <i className="bi bi-link-45deg"></i> Edit Link
                                </button>
                                <button
                                    className="bg-blue-500 text-white rounded-md px-4 py-2 hover:bg-blue-600 transition-colors duration-200"
                                    onClick={navigateToLink}
                                >
                                    <i className="bi bi-box-arrow-up-right"></i> Artist Link
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default ArtistDetail;
