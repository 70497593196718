import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Login from './core/Login';
import CampaignOverview from './features/pre-save/CampaignOverview';
import CampaignCreate from "./features/pre-save/CampaignCreate";
import CampaignDetailed from "./features/pre-save/CampaignDetailed";
import SavePage from "./features/pre-save/SavePage";
import SavePageRedirect from "./features/pre-save/SavePageRedirect";
import ArtistOverview from "./features/artists/ArtistOverview";
import ArtistDetail from "./features/artists/ArtistDetail";
import ArtistCreate from "./features/artists/ArtistCreate";
import EventOverview from "./features/events/EventOverview";
import EventDetailed from "./features/events/EventDetailed";
import EventSell from "./features/events/EventSell";
import { loadStripe } from "@stripe/stripe-js";
import globals from "./core/globals";
import { Elements } from "@stripe/react-stripe-js";
import EventCreate from "./features/events/EventCreate";
import EventCheckin from "./features/events/EventCheckin";
import CheckoutSuccess from "./features/events/CheckoutSuccess";
import CheckoutConfirmation from "./features/events/CheckoutConfirmation";
import PaymentSuccess from "./features/events/PaymentSuccess";
import BillingComponent from "./features/billing/BillingComponent";
import Imprint from "./core/Imprint";
import PrivacyPolicy from "./core/PrivacyPolicy";
import Registration from "./core/Registration";
import PasswordForgot from "./core/PasswordForgot";
import PasswordResetSet from "./core/PasswordResetSet";
import ArtistLinkCreate from "./features/artists/ArtistLinkCreate";
import ArtistLinkLanding from "./features/artists/ArtistLinkLanding";
import AuthService from "./services/AuthService";
const stripePromise = loadStripe(globals.findStripeKey());

function App() {
    const isLoggedIn = AuthService.isLoggedIn();

    return (
        <Router>
            <Routes>
                <Route path="" element={isLoggedIn ? <ArtistOverview /> : <Login />} />

                <Route path="/register" element={<Registration />} />
                <Route path="/login" element={<Login />} />
                <Route path="passwordReset" element={<PasswordForgot />} />
                <Route path="passwordReset/:token" element={<PasswordResetSet />} />

                <Route path="/:identifier" element={<ArtistLinkLanding />} />

                <Route path="/campaigns" element={<CampaignOverview />} />
                <Route path="/campaigns/create" element={<CampaignCreate />} />
                <Route path="/campaigns/:id" element={<CampaignDetailed />} />
                <Route path="/success" element={<SavePageRedirect />} />
                <Route path="/:artistname/:campaignname" element={<SavePage />} />

                <Route path="/order/success" element={<CheckoutSuccess />} />

                <Route path="/artists" element={<ArtistOverview />} />
                <Route path="/artists/:id" element={<ArtistDetail />} />
                <Route path="/artists/create" element={<ArtistCreate />} />
                <Route path="/artists/edit/:id" element={<ArtistCreate />} />

                <Route path="/artists/link/edit/:artistId" element={<ArtistLinkCreate />} />

                <Route path="/events" element={<EventOverview />} />
                <Route path="/events/:id" element={<EventDetailed />} />
                <Route path="/events/edit/:id" element={<EventCreate />} />
                <Route path="/event/payment/success" element={<PaymentSuccess />} />

                <Route path="confirm/event/:id" element={<CheckoutConfirmation />} />

                <Route path="/events/checkin/:id" element={<EventCheckin />} />

                <Route path="/events/create" element={<EventCreate />} />

                <Route
                    path="/events/:artistname/:id"
                    element={
                        <Elements stripe={stripePromise}>
                            <EventSell />
                        </Elements>
                    }
                />

                <Route path="billing" element={<BillingComponent />} />
                <Route path="imprint" element={<Imprint />} />
                <Route path="privacy" element={<PrivacyPolicy />} />
            </Routes>
        </Router>
    );
}

export default App;
