import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import PaymentService from '../../services/PaymentService';
import Header from '../../core/Header';

function PaymentSuccess() {
    const [loading, setLoading] = useState(true);
    const [success, setSuccess] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [searchParams] = useSearchParams();

    useEffect(() => {
        const paymentIntentId = searchParams.get('payment_intent');

        const confirmPayment = async () => {
            try {
                const response = await PaymentService.confirmStripePayment(paymentIntentId);
                console.log('Payment confirmation:', response.data);
                setSuccess(true);
            } catch (error) {
                console.error('Error confirming payment:', error);
                setErrorMessage(error.response?.data || 'Something went wrong. Please try again.');
                setSuccess(false);
            } finally {
                setLoading(false);
            }
        };

        if (paymentIntentId) {
            confirmPayment();
        } else {
            setLoading(false);
            setSuccess(false);
            setErrorMessage('Payment intent not found.');
        }
    }, [searchParams]);

    return (
        <div className="bg-gray-50 min-h-screen">
            <Header showNavbar={false} />
            <div className="container mx-auto py-16">
                {loading ? (
                    <div className="text-center">
                        <h3 className="text-2xl font-bold text-gray-800">Processing Payment...</h3>
                    </div>
                ) : success ? (
                    <div className="bg-white rounded-lg shadow-lg p-8 text-center">
                        <h3 className="text-2xl font-bold text-gray-800 mb-4">Payment successful!</h3>
                        <p className="text-lg text-gray-600 mb-4">
                            In case of any problem, contact{' '}
                            <a href="mailto:max@onelastglance.com" className="text-blue-500">
                                max@onelastglance.com
                            </a>
                        </p>
                    </div>
                ) : (
                    <div className="bg-white rounded-lg shadow-lg p-8 text-center">
                        <h3 className="text-2xl font-bold text-gray-800 mb-4">Something went wrong</h3>
                        <p className="text-lg text-red-600 mb-4">{errorMessage}</p>
                        <p className="text-lg text-gray-600">
                            In case of any problem, contact{' '}
                            <a href="mailto:max@onelastglance.com" className="text-blue-500">
                                max@onelastglance.com
                            </a>
                        </p>
                    </div>
                )}
            </div>
        </div>
    );
}

export default PaymentSuccess;
