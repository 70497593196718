import React, {useEffect, useState} from 'react';
import ArtistService from '../../services/ArtistService';
import {useNavigate} from 'react-router-dom';
import Header from '../../core/Header';
import {FaPlus} from "react-icons/fa";

function ArtistOverview() {
    const [artists, setArtists] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        loadData();
    }, []);

    const loadData = async () => {
        setLoading(true);
        try {
            const response = await ArtistService.getArtistsForAuthenticatedUser();
            const sortedArtists = response.data.sort((a, b) => a.name.localeCompare(b.name));
            setArtists(sortedArtists);
        } catch (error) {
            console.error("Failed to load artists:", error);
            // Handle error
        } finally {
            setLoading(false);
        }
    };

    const createArtist = () => {
        navigate('/artists/create');
    };

    const deleteArtist = async (id) => {
        if (window.confirm("Do you really want to delete this artist? All campaigns will be lost!")) {
            try {
                await ArtistService.deleteArtist(id);
                loadData();
            } catch (error) {
                console.error("Failed to delete artist:", error);
                // Handle error
            }
        }
    };

    const formatNumber = (num) => {
        return num?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    };

    const navigateToArtistDetail = (id) => {
        navigate(`/artists/${id}`);
    };

    return (
        <div className="min-h-screen bg-gray-100">
            <Header isLoggedIn={true} logoutUser={() => console.log('Logged out')}/>
            <div className="container mx-auto p-6">
                <div className="flex justify-between items-center mb-6">
                    <h1 className="text-3xl font-bold text-gray-800">Your Artists</h1>
                    <button
                        className="bg-blue-500 text-white px-4 py-2 rounded-md shadow-md hover:bg-blue-600 flex items-center"
                        onClick={() => navigate('/artists/create')}
                    >
                        <FaPlus className="mr-2"/> Artist
                    </button>
                </div>

                {loading ? (
                    <div className="text-center">
                        <span className="spinner-border spinner-border-sm mr-2"></span> Loading Artists
                    </div>
                ) : (
                    <>
                        {!artists.length ? (
                            <div className="text-center">
                                <h3>No artists yet</h3>
                            </div>
                        ) : (
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                                {artists.map((artist) => (
                                    <div
                                        key={artist.id}
                                        className="block border rounded-lg p-4 mb-4 shadow-sm hover:shadow-lg transition-shadow duration-200 flex items-center justify-between cursor-pointer"
                                        onClick={() => navigateToArtistDetail(artist.id)}
                                    >
                                        <div className="flex items-center">
                                            {artist.imageUrl ? (
                                                <img src={artist.imageUrl} alt={artist.name}
                                                     className="w-16 h-16 rounded-full mr-4"/>
                                            ) : (
                                                <div
                                                    className="w-16 h-16 bg-gray-200 rounded-full flex items-center justify-center mr-4">
                                                    <span>No Image</span>
                                                </div>
                                            )}
                                            <div>
                                                <h4 className="text-xl font-bold text-gray-800">{artist.name}</h4>
                                                {artist.followers && artist.followers > 0 && (
                                                    <p className="text-gray-600">Followers: {formatNumber(artist.followers)}</p>
                                                )}
                                            </div>

                                        </div>

                                    </div>
                                ))}
                            </div>
                        )}
                    </>
                )}
            </div>
        </div>
    );
}

export default ArtistOverview;
